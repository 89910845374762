import { CreativeBasicFormModel, DefaultCreativeBasicFormModel } from './SubSteps/CreativeBasicFormModel';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { AddonFeatureManager } from 'core';
import { OPERATE } from 'enum/Operate';
import { DefaultEditLimitationModel, EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { CreativeFormBasicData, FormContentModel } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/FormContent/FormContentModel';
import { CreativeType } from 'core/creative/Creative';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { get, isEmpty, omit } from 'lodash';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { AdLogoFormModel, DefaultAdLogoFormModel } from 'components/AdLogoForm/AdLogoFormModel';
import { getDefaultLimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/defaultLimitationInventorySettings';
import { DEFAULT_INVENTORY, DefaultLimitationInventorySettings, EmptyLimitationInventorySettings, LIMITATION_TYPE, LimitationInventorySettings } from 'containers/Limitations/LimitationSetting/limitationConfig/limitationSettingsType';
import { LimitationData } from 'core/limitation/Limitation';

export interface CreativeSetupStepModel {
  readonly type: string;
  readonly activeTab: number;
  readonly state: CreativeSetupStepState;
  readonly event: UpdateEventListener<CreativeSetupStepModel>;
  goSubStep: (subStepIndex: number) => void;
  goLast?: () => void;
  goNext: (callback) => void;
  getBasicFormModel (
    creative: CreativeFormBasicData,
    tenmaxCategories: Array<SelectOptions>,
    supportedCreativeType: CreativeType[],
    forPmp: boolean,
    formContentModelGetter: (creativeType: CreativeType) => FormContentModel | undefined,
    addLimitation: (operate: string, limitationType: string, label: string, value: string) => void
  ): CreativeBasicFormModel;
  getLimitationModel (limitations: any, advertiserId: number): EditLimitationModel;
  getAdLogoFormModel (): AdLogoFormModel;
  getEditedCreative (): any;
  updateProps (activeTab: number): void;
  validate: () => any;
}

export type CreativeSetupStepProps = {
  readonly model: CreativeSetupStepModel;
};

export type CreativeSetupStepState = {
  readonly currentCreativeType: CreativeType;
};

export enum CreativeSetupTab {
  BASIC,
  LIMITATION,
  ADLOGO
}

export class DefaultCreativeSetupStepModel implements CreativeSetupStepModel {

  stepChangeListener?: (stepIndex, targetSubStep?: string) => void;
  basicFormModel?: CreativeBasicFormModel;
  limitationModel?: EditLimitationModel;
  event: FireableUpdateEventListener<CreativeSetupStepModel>;
  adLogoFormModel?: AdLogoFormModel;

  constructor (
    private currentCreativeType: CreativeType,
    public type: string,
    public activeTab: number,
    public goSubStep: (subStepIndex: number) => void,
    public goLast: (() => void) | undefined,
    public goNext: () => void,
    private addonFeatureManager: AddonFeatureManager,
    registerValidateMethod: (validateMethod) => void,
    private setEnableAdLogo: (enable: boolean) => void,
    private creativeManager: CreativeManager = new DefaultCreativeManager(),
    private adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager()
  ) {
    this.event = new FireableUpdateEventListener<CreativeSetupStepModel>();
    registerValidateMethod(this.validate);
  }

  get state () {
    return {
      currentCreativeType: this.currentCreativeType
    };
  }

  onCreativeTypeChange = (creativeType: CreativeType, contentModel: FormContentModel) => {
    if (this.limitationModel) {
      const initLimitations = contentModel.getInitLimitations();
      this.limitationModel.updateLimitationValue(initLimitations);
      if (this.currentCreativeType === CreativeType.CTV || creativeType === CreativeType.CTV) {
        this.limitationModel.cleanTaOptionsCache('device');
      }
      this.limitationModel.showInventory();
    }

    this.currentCreativeType = creativeType;
    contentModel && this.setEnableAdLogo(!!contentModel.defaultAdLogo);
    this.updateState();
  }

  getBasicFormModel (
    creative: CreativeFormBasicData,
    tenmaxCategories: SelectOptions[],
    supportedCreativeType: CreativeType[],
    forPmp: boolean,
    formContentModelGetter: (creativeType: CreativeType) => FormContentModel,
    addLimitation: (operate: string, limitationType: string, label: string, value: string) => void
  ) {
    if (this.basicFormModel && creative.advertiserId === this.basicFormModel.initCreative.advertiserId) {
      this.basicFormModel.setLimitationHook(addLimitation);
      return this.basicFormModel;
    }
    this.basicFormModel = new DefaultCreativeBasicFormModel(
      this.type,
      creative,
      tenmaxCategories,
      this.creativeManager,
      supportedCreativeType,
      (creativeType) => {
        const contentModel = formContentModelGetter(creativeType);
        this.onCreativeTypeChange(creativeType, contentModel);
      },
      formContentModelGetter,
      addLimitation,
      forPmp
    );
    return this.basicFormModel;
  }

  getLimitationSettings (advertiserId: number) {
    const isEmptyLimitationSetting = (limitationSetting: LimitationInventorySettings): limitationSetting is EmptyLimitationInventorySettings => {
      return limitationSetting.name === DEFAULT_INVENTORY;
    };
    const limitationSettings: LimitationInventorySettings[] = getDefaultLimitationInventorySettings({}, advertiserId, LIMITATION_TYPE.CREATIVE)
      .filter((limitationSetting) => [DEFAULT_INVENTORY, 'device', 'os'].includes(limitationSetting.name))
      .map(limitationSetting => {
        if (isEmptyLimitationSetting(limitationSetting)) {
          return limitationSetting;
        }
        return omit({
          ...limitationSetting,
          ignoreAddonFeature: true
        }, ['addonFeature']) as DefaultLimitationInventorySettings;
      });

    if (CreativeType.VIDEO === this.currentCreativeType) {
      const videoSetting = limitationSettings.find(setting => setting.name === 'device');
      videoSetting && (videoSetting.requiredOperate = [OPERATE.INCLUDE]);
    }
    return limitationSettings;
  }

  getLimitationModel (limitations: LimitationData, advertiserId: number) {
    const addonFeature = [...this.addonFeatureManager.addonFeature];
    const limitationSettings = this.getLimitationSettings(advertiserId);
    const isCtvCreative = this.currentCreativeType === CreativeType.CTV;
    if (isCtvCreative) {
      const deviceSetting = limitationSettings.find(setting => setting.name === 'device');
      if (deviceSetting) {
        deviceSetting.requiredOperate = [OPERATE.INCLUDE];
        deviceSetting.cb = async () => {
          const originalOptions = await this.adRequestSourceManager.getDevice();
          return originalOptions.filter(option => ['3', '6', '7'].includes(option.value.toString()));
        };
      }
    }

    if (this.limitationModel) {
      this.limitationModel.setLimitationSetting(limitationSettings);
      return this.limitationModel;
    }

    this.limitationModel = new DefaultEditLimitationModel(
      limitationSettings,
      limitations,
      { need: [OPERATE.INCLUDE, OPERATE.PREFERRED, OPERATE.NONPREFERRED], notNeed: [OPERATE.EXCLUDE] },
      addonFeature
    );

    return this.limitationModel;
  }

  getAdLogoFormModel (): AdLogoFormModel {
    if (this.adLogoFormModel) {
      return this.adLogoFormModel;
    }

    this.adLogoFormModel = new DefaultAdLogoFormModel();
    return this.adLogoFormModel;
  }

  updateProps (activeTab: number): void {
    this.activeTab = activeTab;
  }

  validate = async () => {
    // TODO if has limitation tab, need call this function when click submit button
    const basicErrors = this.basicFormModel ? await this.basicFormModel.validate() : {};
    const adLogoErrors = get(basicErrors, 'adLogo', {});
    if (!isEmpty(adLogoErrors)) {
      delete basicErrors['adLogo'];
    }
    const basicPageErrorNames = Object.keys(basicErrors);
    const adLogoPageErrorNames = Object.keys(adLogoErrors);
    const limitationError = this.limitationModel ? await this.limitationModel.validate() : {};
    const limitationErrorNames = Object.keys(limitationError);
    if (basicPageErrorNames.length > 0) {
      this.goSubStep(CreativeSetupTab.BASIC);
    } else if (limitationErrorNames.length > 0) {
      this.goSubStep(CreativeSetupTab.LIMITATION);
    } else if (adLogoPageErrorNames.length > 0) {
      this.goSubStep(CreativeSetupTab.ADLOGO);
    }
    return [...basicPageErrorNames, ...limitationErrorNames, ...adLogoPageErrorNames];
  }

  getEditedCreative = () => {
    if (!this.basicFormModel) {
      return {};
    }

    return {
      basic: this.basicFormModel.getCreativeBasicValue()
    };
  }

  updateState () {
    this.event.fireEvent(this);
  }
}
