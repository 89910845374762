import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { CreativeOverviewState, BindCreativeState, CreativeSummaryState, ModifyCreativeActivationState } from './CreativeManagementState';
import { ModalStateFactory, ModalState } from 'containers/Common/ModalStateFactory';
import { CreativeOfCampaign } from 'core/creative/Creative';
import { AddonFeatureManager } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import * as H from 'history';
import { RtbBindCreativeStateContentModel, FbBindCreativeStateContentModel, RetailBindCreativeStateContentModel, EdiMaxBindCreativeStateContentModel, PICBindCreativeStateContentModel } from './BindCreativeStateContentModel';
import { RtbCreativeSummaryStateContentModel, FbCreativeSummaryStateContentModel, RetailCreativeSummaryStateContentModel, EdiMaxCreativeSummaryStateContentModel, PICCreativeSummaryStateContentModel } from './CreativeSummaryStateContentModel';
import { RtbModifyCreativeActivationStateContentModel, FbModifyCreativeActivationStateContentModel, RetailModifyCreativeActivationStateContentModel, EdiMaxModifyCreativeActivationStateContentModel, PICModifyCreativeActivationStateContentModel } from './ModifyCreativeActivationStateContentModel';
import { RtbCreativeOverviewStateContentModel, FbAdOverviewStateContentModel, RetailCreativeOverviewStateContentModel, EdiMaxCreativeOverviewStateContentModel, PICCreativeOverviewStateContentModel } from './CreativeOverviewStateContentModel';
import { CreativeBindData, CreativeManagementData } from 'core/rtbCampaign/CreativeBindData';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import _ from 'lodash';

export enum CreativeManagementAction {
  BIND = 'bind',
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate',
  MANAGE = 'manage'
}
export abstract class CreativeManagementStateFactory implements ModalStateFactory {

  channel: L1ObjectChannel;

  constructor (
    protected campaigns: CreativeBindData[],
    protected advertiserId: number,
    protected editAction: string,
    protected addonFeatureManager: AddonFeatureManager,
    protected orderNumber: string,
    protected history: H.History,
    protected l1Object: L1Object,
    protected manager: CreativeManager = new DefaultCreativeManager()
  ) {
    this.channel = _.get(l1Object, 'channel', L1ObjectChannel.RTB);
    if (this.campaigns.length >= 1 && this.editAction === 'manage') {
      this.campaigns = [this.campaigns[0]];
    }
  }

  async initStates (goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void) {
    const creativeData = await this.getCreativeData();
    switch (this.editAction) {
      case CreativeManagementAction.BIND:
        return this.initBindStates(creativeData, goModalStateFn, closeModalFn);
      case CreativeManagementAction.ACTIVATE:
        return this.initModifyActivationStates(creativeData, goModalStateFn, closeModalFn, true);
      case CreativeManagementAction.DEACTIVATE:
        return this.initModifyActivationStates(creativeData, goModalStateFn, closeModalFn, false);
      default:
        return this.initManageStates(creativeData, goModalStateFn, closeModalFn);
    }
  }

  async getCreativeData (): Promise<CreativeManagementData[]> {
    let creativeData: CreativeManagementData[] = [];
    try {
      await Promise.all(
        this.campaigns.map(async campaign => {
          try {
            const creatives = await this.getCreatives(campaign.id, campaign.draftId);
            creativeData.push({
              id: campaign.id,
              name: campaign.name,
              startDate: campaign.startDate,
              endDate: campaign.endDate,
              creatives: creatives,
              draftId: campaign.draftId
            });
          } catch (e) {
            creativeData.push({
              id: campaign.id,
              name: campaign.name,
              startDate: campaign.startDate,
              endDate: campaign.endDate,
              creatives: []
            });
          }
        })
      );
    } catch (error) {
      console.log('cannot get creative datas of campaigns', error);
    }
    creativeData = creativeData.sort((campaign1, campaign2) => {
      return campaign2.id - campaign1.id;
    });
    return creativeData;
  }

  abstract getCreatives (l2ChannelId: number, draftId?: number);

  abstract getOverviewStateContentModel (creatives: CreativeOfCampaign[], l3ObjectStateFn: () => void, refreshCreative: () => Promise<CreativeOfCampaign[]>);

  abstract getBindCreativeStateContentModel (forbidCreatives: number[]);

  abstract getModifyCreativeActivationStateContentModel (creativeData: CreativeManagementData[], activate: boolean);

  abstract getCreativeSummaryStateContentModel (creativeData: CreativeManagementData[]);

  async initManageStates (creativeData: CreativeManagementData[], goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void) {
    const refreshCreative = async () => {
      const newCreativeData = await this.getCreativeData();
      return newCreativeData[0].creatives;
    };
    let creatives: CreativeOfCampaign[] = creativeData[0].creatives;
    let bindCreativeState = new BindCreativeState(
      this.campaigns,
      this.getBindCreativeStateContentModel(creatives.map(creative => creative.id))
    );
    let creativeOverviewState = new CreativeOverviewState(
      this.campaigns,
      this.getOverviewStateContentModel(
        creatives,
        () => {
          bindCreativeState.contentModel.reset();
          goModalStateFn(bindCreativeState);
        },
        refreshCreative)
    );

    let creativeSummaryState = new CreativeSummaryState(
      this.campaigns,
      this.getCreativeSummaryStateContentModel(creativeData)
    );

    creativeOverviewState.setCloseFn(closeModalFn);
    bindCreativeState.setNextFn(() => goModalStateFn(creativeSummaryState));
    bindCreativeState.setBackFn(() => goModalStateFn(creativeOverviewState));
    creativeSummaryState.setBackFn(() => goModalStateFn(bindCreativeState));
    creativeSummaryState.setCloseFn(closeModalFn);
    return creativeOverviewState;
  }

  async initBindStates (creativeData: CreativeManagementData[], goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void) {
    const forbidCreatives = creativeData.length === 1 ? creativeData[0].creatives.map(creative => creative.id) : [];
    let bindCreativeState = new BindCreativeState(
      this.campaigns,
      this.getBindCreativeStateContentModel(forbidCreatives)
    );
    let creativeSummaryState = new CreativeSummaryState(
      this.campaigns,
      this.getCreativeSummaryStateContentModel(creativeData)
    );
    bindCreativeState.setNextFn(() => goModalStateFn(creativeSummaryState));
    bindCreativeState.setCloseFn(closeModalFn);
    creativeSummaryState.setBackFn(() => goModalStateFn(bindCreativeState));
    creativeSummaryState.setCloseFn(closeModalFn);
    return bindCreativeState;
  }

  async initModifyActivationStates (creativeData: CreativeManagementData[], goModalStateFn: (state: ModalState, beforeGoState?: () => Promise<void>) => void, closeModalFn: (dirty) => void, activate: boolean) {
    let modifyCreativeActivationState = new ModifyCreativeActivationState(
      this.campaigns,
      this.getModifyCreativeActivationStateContentModel(creativeData, activate)
    );
    let creativeSummaryState = new CreativeSummaryState(
      this.campaigns,
      this.getCreativeSummaryStateContentModel(creativeData)
    );

    modifyCreativeActivationState.setNextFn(() => goModalStateFn(creativeSummaryState));
    modifyCreativeActivationState.setCloseFn(closeModalFn);
    creativeSummaryState.setBackFn(() => goModalStateFn(modifyCreativeActivationState));
    creativeSummaryState.setCloseFn(closeModalFn);
    return modifyCreativeActivationState;
  }

  goCreateCreativeFn = () => {
    const campaignParams = this.campaigns[0].draftId ? `draftId=${this.campaigns[0].draftId}` : `campaignId=${this.campaigns[0].id}`;
    let path = `/creatives/new?${campaignParams}&orderNumber=${this.orderNumber}`;
    if (this.l1Object) {
      path = `${path}&l1ObjectId=${this.l1Object.l1ObjectId}&channel=${this.channel}`;
    }
    this.history.push(path);
  }
}

export class RtbCreativeManagementStateFactory extends CreativeManagementStateFactory {

  async getCreatives (l2ChannelId: number, draftId?: number) {
    if (draftId) {
      return this.manager.getCreativesByCampaignId(draftId, true);
    }
    return this.manager.getCreativesByCampaignId(l2ChannelId, false);
  }

  getOverviewStateContentModel (creatives: CreativeOfCampaign[], l3ObjectStateFn: () => void, refreshCreative: () => Promise<CreativeOfCampaign[]>) {
    return new RtbCreativeOverviewStateContentModel(
      this.l1Object,
      this.campaigns[0],
      creatives,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT),
      this.orderNumber,
      l3ObjectStateFn,
      this.goCreateCreativeFn,
      refreshCreative
    );
  }

  getBindCreativeStateContentModel (forbidCreatives: number[]) {
    return new RtbBindCreativeStateContentModel(
      this.campaigns,
      this.advertiserId,
      forbidCreatives,
      this.manager
    );
  }

  getModifyCreativeActivationStateContentModel (creativeData: CreativeManagementData[], activate: boolean) {
    return new RtbModifyCreativeActivationStateContentModel(creativeData, activate, this.l1Object);
  }

  getCreativeSummaryStateContentModel (creativeData: CreativeManagementData[]) {
    return new RtbCreativeSummaryStateContentModel(creativeData);
  }
}

export class RetailCreativeManagementStateFactory extends CreativeManagementStateFactory {

  constructor (
    campaigns: CreativeBindData[],
    advertiserId: number,
    editAction: string,
    addonFeatureManager: AddonFeatureManager,
    orderNumber: string,
    history: H.History,
    l1Object: L1Object,
    private retailerId?: string,
    manager: CreativeManager = new DefaultCreativeManager()
  ) {
    super(campaigns, advertiserId, editAction, addonFeatureManager, orderNumber, history, l1Object, manager);
  }

  async getCreatives (l2ChannelId: number, draftId?: number) {
    let creatives;
    if (draftId) {
      creatives = await this.manager.getCreativesByCampaignId(draftId, true);
    } else {
      creatives = await this.manager.getCreativesByCampaignId(l2ChannelId, false);
    }
    return _.map(creatives, creative => {
      const ppsLayoutId = _.get(creative, 'ppsLayoutId');
      return {
        ...creative,
        ppsLayoutId: ppsLayoutId ? ppsLayoutId.replace('pps_', '') : undefined
      };
    });
  }

  getOverviewStateContentModel (creatives: CreativeOfCampaign[], l3ObjectStateFn: () => void, refreshCreative: () => Promise<CreativeOfCampaign[]>) {
    return new RetailCreativeOverviewStateContentModel(
      this.l1Object,
      this.campaigns[0],
      creatives,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT),
      this.orderNumber,
      l3ObjectStateFn,
      this.goCreateCreativeFn,
      refreshCreative
    );
  }

  getBindCreativeStateContentModel (forbidCreatives: number[]) {
    return new RetailBindCreativeStateContentModel(
      this.campaigns,
      this.advertiserId,
      forbidCreatives,
      this.retailerId,
      this.manager
    );
  }

  getModifyCreativeActivationStateContentModel (creativeData: CreativeManagementData[], activate: boolean) {
    return new RetailModifyCreativeActivationStateContentModel(creativeData, activate, this.l1Object);
  }

  getCreativeSummaryStateContentModel (creativeData: CreativeManagementData[]) {
    return new RetailCreativeSummaryStateContentModel(creativeData);
  }
}

export class EdiMaxCreativeManagementStateFactory extends CreativeManagementStateFactory {

  async getCreatives (l2ChannelId: number, draftId?: number) {
    if (draftId) {
      return this.manager.getCreativesByCampaignId(draftId, true);
    }
    return this.manager.getCreativesByCampaignId(l2ChannelId, false);
  }

  getOverviewStateContentModel (creatives: CreativeOfCampaign[], l3ObjectStateFn: () => void, refreshCreative: () => Promise<CreativeOfCampaign[]>) {
    return new EdiMaxCreativeOverviewStateContentModel(
      this.l1Object,
      this.campaigns[0],
      creatives,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT),
      this.orderNumber,
      l3ObjectStateFn,
      this.goCreateCreativeFn,
      refreshCreative
    );
  }

  getBindCreativeStateContentModel (forbidCreatives: number[]) {
    return new EdiMaxBindCreativeStateContentModel(
      this.campaigns,
      this.advertiserId,
      forbidCreatives,
      this.manager
    );
  }

  getModifyCreativeActivationStateContentModel (creativeData: CreativeManagementData[], activate: boolean) {
    return new EdiMaxModifyCreativeActivationStateContentModel(creativeData, activate, this.l1Object);
  }

  getCreativeSummaryStateContentModel (creativeData: CreativeManagementData[]) {
    return new EdiMaxCreativeSummaryStateContentModel(creativeData);
  }
}

export class PICCreativeManagementStateFactory extends CreativeManagementStateFactory {

  async getCreatives (l2ChannelId: number, draftId?: number) {
    if (draftId) {
      return this.manager.getCreativesByCampaignId(draftId, true);
    }
    return this.manager.getCreativesByCampaignId(l2ChannelId, false);
  }

  getOverviewStateContentModel (creatives: CreativeOfCampaign[], l3ObjectStateFn: () => void, refreshCreative: () => Promise<CreativeOfCampaign[]>) {
    return new PICCreativeOverviewStateContentModel(
      this.l1Object,
      this.campaigns[0],
      creatives,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT),
      this.orderNumber,
      l3ObjectStateFn,
      this.goCreateCreativeFn,
      refreshCreative
    );
  }

  getBindCreativeStateContentModel (forbidCreatives: number[]) {
    return new PICBindCreativeStateContentModel(
      this.campaigns,
      this.advertiserId,
      forbidCreatives,
      this.manager
    );
  }

  getModifyCreativeActivationStateContentModel (creativeData: CreativeManagementData[], activate: boolean) {
    return new PICModifyCreativeActivationStateContentModel(creativeData, activate, this.l1Object);
  }

  getCreativeSummaryStateContentModel (creativeData: CreativeManagementData[]) {
    return new PICCreativeSummaryStateContentModel(creativeData);
  }
}

export class FbAdManagementStateFactory extends CreativeManagementStateFactory {

  async getCreatives (l2ChannelId: number, draftId?: number) {
    if (draftId) {
      return this.manager.getCreativesByAdSetId(draftId, true);
    }
    return this.manager.getCreativesByAdSetId(l2ChannelId, false);
  }

  getBindCreativeStateContentModel (forbidCreatives: number[]) {
    return new FbBindCreativeStateContentModel(
      this.campaigns,
      this.advertiserId,
      forbidCreatives,
      this.manager
    );
  }

  getOverviewStateContentModel (creatives: CreativeOfCampaign[], l3ObjectStateFn: () => void, refreshCreative: () => Promise<CreativeOfCampaign[]>) {
    return new FbAdOverviewStateContentModel(
      this.l1Object,
      this.campaigns[0],
      creatives,
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT),
      this.orderNumber,
      l3ObjectStateFn,
      this.goCreateCreativeFn,
      refreshCreative
    );
  }

  getModifyCreativeActivationStateContentModel (creativeData: CreativeManagementData[], activate: boolean) {
    return new FbModifyCreativeActivationStateContentModel(creativeData, activate, this.l1Object);
  }

  getCreativeSummaryStateContentModel (creativeData: CreativeManagementData[]) {
    return new FbCreativeSummaryStateContentModel(creativeData);
  }
}
