import _, { camelCase, upperFirst } from 'lodash';
import { AdType, CreativeDeliverType, RtbCampaignPlanType, VideoAdViewObjective, DeliverType, CampaignState, OutdoorDeliverType } from 'core/rtbCampaign/RtbCampaign';
import { getDayOfWeekLabelByValue } from 'components/Dayparts/Dayparts';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import { Currency } from 'core';
import { toast } from 'react-toastify';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { RtbCampaignManager, DefaultRtbCampaignManager, LegacyEstimateViewModelData } from 'core/rtbCampaign/RtbCampaignManager';
import { Order, OrderType } from 'core/order/Order';
import { CampaignSetupTab } from './RtbCampaignSetupStepModel';
import i18n from 'i18next';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { CreateRtbCampaignSetupFlowStep, CreateRtbCampaignWithCreativeSetupFlowStep, EditRtbCampaignSetupFlowStep } from '../RtbCampaignSetupFlowStep';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import moment from 'moment';
import { DraftManager, RtbCampaignDraftManager } from 'core/draft/DraftManager';
import { BidStrategy } from 'core/l2Object/L2Object';
import { PmpFormModel } from '../RtbCampaignForm/PmpFormModel';
import { getPmpSummaryData } from 'containers/PmpOrder/PmpSummaryModel';
import { CreativeSummaryStepModel, CreativeSummaryStepState } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/CreativeSummaryStepModel';
import { FlowSummarySectionProps, SummarySectionData } from 'components/FlowSummarySection/FlowSummarySection';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { renderOverBudgetWording } from 'containers/L2Objects/FormHintRenderFunctions';

export type RtbCampaignSummaryStepState = {
  readonly loading: boolean;
  readonly showPublishBindingFailed: boolean;
  readonly showAddSaveTargetingModal: boolean;
  readonly legacyEstimateData?: LegacyEstimateViewModelData;
  readonly creativeSummaryStepState?: CreativeSummaryStepState;
};

export interface RtbCampaignSummaryStepModel {
  readonly flowModel: RtbCampaignSetupFlowPageModel;
  readonly event: UpdateEventListener<RtbCampaignSummaryStepModel>;
  readonly state: RtbCampaignSummaryStepState;
  readonly targetingValue: any;
  readonly estimatedAudience?: EstimatedAudience;
  readonly audienceLowestThreshold: number;
  readonly appliedSavedTAInfo?: SelectOptions;
  readonly savedTargetingList: SelectOptions[];
  readonly order: Order;
  readonly l1Object?: L1Object;
  readonly noLegacyEstimateDes: string;
  readonly showLegacyEstimation: boolean;
  readonly creativeSummaryStepModel?: CreativeSummaryStepModel;
  readonly subStepIndex: number;
  updateState (loading: boolean): void;
  getAdTypeSummaryData: () => FlowSummarySectionProps;
  getSetUpSummaryData: () => FlowSummarySectionProps;
  getPmpSummaryData: () => FlowSummarySectionProps | undefined;
  getPmpErrors: () => { [key: string]: string | JSX.Element };
  getLimitationsSummaryData: () => FlowSummarySectionProps;
  getTrackingSummaryData: () => FlowSummarySectionProps | undefined;
  getCreativeBindingSummaryData: () => FlowSummarySectionProps | undefined;
  submit: () => void;
  goLast: () => void;
  goStep: (stepIndex: number, subStepIndex: number) => void;
  goSubStep: (subStepIndex: number) => void;
  getLegacyEstimateData: () => Promise<void>;
  onShowAddSaveTargetingModal: (showModal: boolean) => void;
  redirectToLastPage: () => void;
  initCreativeSummaryModel: () => void;
  unmount: (eventHandler?: number) => void;
}

export type RtbCampaignSummaryStepProps = {
  readonly model: RtbCampaignSummaryStepModel;
};

export class DefaultRtbCampaignSummaryStepModel implements RtbCampaignSummaryStepModel {

  event: FireableUpdateEventListener<RtbCampaignSummaryStepModel>;
  loading: boolean;
  showPublishBindingFailed: boolean = false;
  showAddSaveTargetingModal: boolean = false;
  stepChangeListener?: (stepIndex, subStepName?: string) => void;
  legacyEstimateData?: LegacyEstimateViewModelData;
  creativeSummaryStepState?: CreativeSummaryStepState;
  creativeSummaryDataHanlder?: number;

  constructor (
    public flowModel: RtbCampaignSetupFlowPageModel,
    public subStepIndex: number,
    public goLast: () => void,
    public goStep: (stepIndex: number, subStepIndex?: number) => void,
    public goSubStep: (subStepIndex: number) => void,
    public creativeSummaryStepModel?: CreativeSummaryStepModel,
    protected campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager()
  ) {
    this.event = new FireableUpdateEventListener<RtbCampaignSummaryStepModel>();
    this.loading = false;
  }

  get state () {
    return {
      loading: this.loading,
      showPublishBindingFailed: this.showPublishBindingFailed,
      showAddSaveTargetingModal: this.showAddSaveTargetingModal,
      legacyEstimateData: this.legacyEstimateData,
      creativeSummaryStepState: this.creativeSummaryStepState
    };
  }

  get noLegacyEstimateDes () {
    // if (this.flowModel.state.campaign.basic.priceModel === RtbCampaignPlanType.RB) {
    //   return i18n.t<string>('campaignSummary.labels.rbNotEstimateDesc');
    // } else {
    //   return i18n.t<string>('campaignSummary.labels.limitationNotEstimateDesc');
    // }
    return i18n.t<string>('campaignSummary.labels.limitationNotEstimateDesc');
  }

  get showLegacyEstimation () {
    // TODO: adjust to Message channel
    return this.flowModel.order.orderType === OrderType.TENMAX && _.includes([
      L1ObjectChannel.RTB,
      // L1ObjectChannel.EDIMAX,
      L1ObjectChannel.RETAIL_MEDIA
    ], this.flowModel.l1Object.channel);
  }

  get estimatedAudience () {
    return this.flowModel.estimatedAudience;
  }

  get audienceLowestThreshold () {
    return this.flowModel.audienceLowestThreshold;
  }

  get targetingValue () {
    return this.flowModel.limitationModel ? this.flowModel.limitationModel.limitationValue : [];
  }

  get order () {
    return this.flowModel.order;
  }

  get l1Object () {
    return this.flowModel.l1Object;
  }

  get savedTargetingList () {
    return this.flowModel.savedTAList.map(savedTA => ({
      label: savedTA.name,
      value: savedTA.id
    }));
  }

  get appliedSavedTAInfo () {
    return this.flowModel.appliedSavedTAInfo;
  }

  getAdTypeSummaryData () {
    return {
      title: i18n.t<string>('campaignSummary.titles.adType'),
      summaryData: {
        adType: {
          title: undefined,
          content: [
            {
              label: i18n.t<string>('campaign.labels.adType'),
              value: this.getAdTypeValue(this.flowModel.state.campaign.basic.adType)
            }
          ]
        }
      }
    };
  }

  get campaignSetupStepIndex () {
    const isCreate = this.flowModel.type === 'create';
    return isCreate ? CreateRtbCampaignSetupFlowStep.SETUP_CAMPAIGN : EditRtbCampaignSetupFlowStep.SETUP_CAMPAIGN;
  }

  getPmpErrors () {
    const {
      order,
      l1Object,
      state
    } = this.flowModel;

    let errors: {[key: string]: string | JSX.Element} = {};
    if (state.campaign.basic.budget > l1Object.budgetBalance) {
      errors.budget = renderOverBudgetWording(order.currency, l1Object.budgetBalance);
    }
    return errors;
  }

  getPmpSummaryData () {
    if (!this.flowModel.isPmp) {
      return undefined;
    }
    const pmpFormModel = this.flowModel.campaignBasicFormModel as PmpFormModel;
    const {
      pmp,
      pmpSpaceOptions
    } = pmpFormModel;

    const errors = this.getPmpErrors();

    return {
      title: i18n.t<string>('campaignSummary.titles.basicSetting'),
      summaryData: {
        general: {
          title: i18n.t<string>('campaignSummary.titles.generalInformation'),
          content: getPmpSummaryData(pmp, pmpSpaceOptions, this.flowModel.order.currency, errors)
        }
      },
      goStep: () => this.goStep(this.campaignSetupStepIndex, CampaignSetupTab.BASIC)
    };
  }

  getSetUpSummaryData () {
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const priceModelRelatedSummaryData = this.getPriceModelRelatedSummaryData(campaignSetUpData.priceModel);
    return {
      title: i18n.t<string>('campaignSummary.titles.basicSetting'),
      summaryData: _.omitBy({
        general: {
          title: i18n.t<string>('campaignSummary.titles.generalInformation'),
          content: _.compact(_.concat([
            {
              label: i18n.t<string>('campaignSummary.labels.orderName'),
              value: this.flowModel.order.projectName
            },
            campaignSetUpData.retailType ? {
              label: i18n.t<string>('campaignInfo.labels.retailType'),
              value: i18n.t<string>(`retailers.${campaignSetUpData.retailType}`)
            } : undefined,
            {
              label: i18n.t<string>('campaignSummary.labels.campaignName'),
              value: campaignSetUpData.name
            },
            {
              label: i18n.t<string>('campaignSummary.labels.schedule'),
              value: `${campaignSetUpData.startDate} ~ ${campaignSetUpData.endDate}`
            },
            {
              label: i18n.t<string>('campaignSummary.labels.pricingModel'),
              value: this.getCampaignPlanTypeValue(campaignSetUpData.priceModel)
            },
            {
              label: i18n.t<string>('campaignSummary.labels.tags'),
              value: this.getTags(campaignSetUpData.tags)
            },
            {
              label: i18n.t<string>('campaignSummary.labels.enableMonitor'),
              value: campaignSetUpData.enableMonitor ? i18n.t<string>('common.labels.yes') : i18n.t<string>('common.labels.no')
            }
          ], priceModelRelatedSummaryData))
        },
        optimizeSetting: {
          title: i18n.t<string>('adSetSetupFlow.mainStep.fieldset.optimizationTitle'),
          content: _.compact([
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal'),
              value: i18n.t<string>(`optimizationGoal.${campaignSetUpData.optimize.toLowerCase()}`)
            },
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.bidStrategy'),
              value: i18n.t<string>(`l1Object.labels.bidStrategy.${campaignSetUpData.bidStrategy.toLowerCase()}`)
            },
            campaignSetUpData.bidStrategy === BidStrategy.LOWEST_COST_WITH_BID_CAP ? {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.bidControl'),
              value: formatPriceWithCurrency(this.flowModel.order.currency, +(campaignSetUpData.bidPrice))
            } : undefined,
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent'),
              value: campaignSetUpData.priceModel === RtbCampaignPlanType.RS ?
                i18n.t<string>(`rtbCampaign.billingEvent.${campaignSetUpData.priceModel}.${campaignSetUpData.optimize.toLowerCase()}`) :
                i18n.t<string>(`rtbCampaign.billingEvent.${campaignSetUpData.priceModel}`)
            },
            {
              label: i18n.t<string>('campaignInfo.labels.creativeDeliverType'),
              value: this.getCreativeDeliverTypeValue(campaignSetUpData.creativeDeliverType)
            },
            campaignSetUpData.outdoorDeliverType && {
              label: i18n.t<string>('campaignInfo.labels.outdoorDeliverType'),
              value: this.getOutdoorDeliverTypeValue(campaignSetUpData.outdoorDeliverType)
            },
            campaignSetUpData.frequency && !_.isEmpty(campaignSetUpData.frequency) && {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.frequencyControl'),
              value: this.getSessionCappaingCountValue(+campaignSetUpData.frequency.maxFrequency, +campaignSetUpData.frequency.intervalDays)
            },
            campaignSetUpData.dayPart && {
              label: i18n.t<string>('campaignSummary.labels.dayPart'),
              value: this.getDayPartValue(campaignSetUpData.dayPart)
            }
          ])
        }
      }, _.isUndefined),
      goStep: () => this.goStep(this.campaignSetupStepIndex, CampaignSetupTab.BASIC)
    };
  }

  getLimitationsSummaryData (): FlowSummarySectionProps {
    const limitations = this.flowModel.state.campaign.limitations;
    const summaryData = this.flowModel.getLimitationsSummaryData(limitations);
    return {
      title: i18n.t<string>('campaignSummary.titles.targetSetting'),
      summaryData,
      goStep: () => this.goStep(this.campaignSetupStepIndex, CampaignSetupTab.LIMITATION)
    };
  }

  getTrackingSummaryData () {
    const needShowConversionTracking = this.flowModel.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST);
    const needShowThirdPartyTracking = this.flowModel.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.THIRDPARTY_TRACKING);
    const needShowReTargetingTracking = this.flowModel.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.RETARGETING_TRACKER);
    if (
      !needShowConversionTracking &&
      !needShowThirdPartyTracking &&
      !needShowReTargetingTracking
    ) {
      return;
    }
    const campaignBasic = this.flowModel.state.campaign.basic;
    const videoProgressTrackingCode = _.get(campaignBasic, 'videoProgressTrackingCode.code');
    const videoProgressTrackingOffset = _.get(campaignBasic, 'videoProgressTrackingCode.offset');
    const conversionTrackingContent = campaignBasic.conversionTracking && [{
      label: i18n.t<string>('campaignSummary.labels.conversionTracking'),
      value: campaignBasic.conversionTracking.label
    }];
    const conversionTrackingTypeOptions = _.get(this.flowModel.campaignBasicFormModel, 'trackingConfig.conversionTracking.conversionTrackingTypeOptions', []);
    if (conversionTrackingTypeOptions.length > 0 && conversionTrackingContent) {
      conversionTrackingContent.push({
        label: i18n.t<string>('campaignSummary.labels.convTrackEvent'),
        value: i18n.t<string>(`campaignSummary.labels.convTrackEvent${_.startCase(campaignBasic.convTrackEvent)}`) || i18n.t<string>('campaignSummary.labels.convTrackEventClick')
      });
    }
    const summaryData: SummarySectionData = _.omitBy({
      conversionTracking: needShowConversionTracking ? {
        title: i18n.t<string>('campaignSummary.titles.conversionTracking'),
        content: conversionTrackingContent
      } : undefined,
      viewTrackingCodes: needShowThirdPartyTracking ? {
        title: i18n.t<string>('campaignSummary.titles.trackingCodes'),
        content: campaignBasic.viewTrackingCodes && campaignBasic.viewTrackingCodes.length > 0 && [{
          label: i18n.t<string>('campaignSummary.labels.trackingCodes'),
          value: campaignBasic.viewTrackingCodes.map(code => {
            return code.trackingCode;
          }).join('\r\n')
        }]
      } : undefined,
      videoProgressTrackingCode: needShowReTargetingTracking ? {
        title: i18n.t<string>('campaignSummary.titles.videoProgressTrackingCode'),
        content: videoProgressTrackingCode && videoProgressTrackingOffset && [
          {
            label: i18n.t<string>('campaignSummary.labels.videoProgressTrackingCode'),
            value: videoProgressTrackingCode,
            showArea: true
          },
          {
            label: i18n.t<string>('campaignSummary.labels.videoProgressTrackingOffset'),
            value: `${videoProgressTrackingOffset} ${i18n.t<string>('campaign.labels.sec')}`
          }
        ]
      } : undefined
    }, _.isUndefined) as SummarySectionData;
    return {
      title: i18n.t<string>('campaignSummary.titles.trackingSetting'),
      goStep: () => this.goStep(this.campaignSetupStepIndex, CampaignSetupTab.TRACKING),
      summaryData
    };
  }

  getPriceModelRelatedSummaryData (priceModel) {
    const currency = this.flowModel.order.currency;
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const needShowOrderPrice = priceModel !== RtbCampaignPlanType.RS && priceModel !== RtbCampaignPlanType.DCPM;
    const needShowBudget = !_.get(this.flowModel.l1Object, 'autoOptimise', false);
    const needShowDailyTargetBudget = true;
    const needShowVideoAdViewObjective = campaignSetUpData.videoAdViewObjective && (
      ((campaignSetUpData.adType === AdType.VIDEO || campaignSetUpData.adType === AdType.COMBO || campaignSetUpData.adType === AdType.UNKNOW) && priceModel === RtbCampaignPlanType.FCPV) ||
      campaignSetUpData.adType === AdType.THIRD_PARTY_BOTTOM
    );
    return _.compact([
      (needShowOrderPrice && campaignSetUpData.orderPrice) && {
        label: i18n.t<string>('campaignSummary.labels.orderPrice', { model: this.getCampaignPlanTypeValue(priceModel) }),
        value: `${currency} ${campaignSetUpData.orderPrice}`
      },
      (needShowBudget) && {
        label: i18n.t<string>('campaignSummary.labels.campaignBudget'),
        value: `${currency} ${campaignSetUpData.budget}`
      },
      (needShowDailyTargetBudget) && {
        label: i18n.t<string>('campaignSummary.labels.budgetDistribution'),
        value: this.getOptimizeBudgetValue(campaignSetUpData.dailyTargetBudget)
      },
      (needShowVideoAdViewObjective) && {
        label: i18n.t<string>('campaignSummary.labels.videoAdViewObjective'),
        value: this.getVideoAdViewObjective(campaignSetUpData.videoAdViewObjective)
      }
    ]);
  }

  getCreativeBindingSummaryData () {
    const campaignBasic = this.flowModel.state.campaign.basic;
    const bindings = campaignBasic.bindings;
    if (bindings && bindings.length > 0) {
      const now = moment();
      const isDeliverying = now.isAfter(moment(campaignBasic.startDate)) && now.isBefore(moment(campaignBasic.endDate));
      const isActive = campaignBasic.state === CampaignState.ACTIVATE;
      const hasActiveBinding = bindings.find(binding => binding.active) !== undefined;
      const showAlert = isDeliverying && isActive && hasActiveBinding;
      return {
        title: i18n.t<string>('campaignSetupFlow.summaryStep.draftBindingTitle'),
        summaryData: {
          draftBinding: {
            content: _.compact([
              {
                label: i18n.t<string>('campaignSetupFlow.summaryStep.labels.draftBinding'),
                value: bindings.length,
                hintColor: SummaryTitleColor.RED,
                hint: showAlert ? i18n.t<string>('campaignSetupFlow.summaryStep.message.willDeliverImmediately') : undefined
              }
            ])
          }
        }
      };
    }
    return;
  }

  getOptimizeBudgetValue (dailyTargetBudget) {
    const currency = this.flowModel ? this.flowModel.order.currency : Currency.NTD;
    if (dailyTargetBudget === null || dailyTargetBudget === undefined || dailyTargetBudget.toString() === '0') {
      return i18n.t<string>('campaignSummary.labels.budgetScheduleDistribution');
    } else {
      return i18n.t<string>('campaignSummary.labels.budgetDailyDistribution', { currency: currency, budget: dailyTargetBudget });
    }
  }

  getVideoAdViewObjective (videoAdViewObjective) {
    if (videoAdViewObjective.videoAdEvent === VideoAdViewObjective.METRIC) {
      return i18n.t<string>('campaignSummary.labels.videoAdViewObjectiveMetric', { metric: i18n.t<string>(`campaign.labels.${videoAdViewObjective.videoAdMetricEvent.toLowerCase()}`) });
    } else if (videoAdViewObjective.videoAdEvent === VideoAdViewObjective.PROGRESS) {
      return i18n.t<string>('campaignSummary.labels.videoAdViewObjectiveProgress', { offset: videoAdViewObjective.offset });
    } else {
      return i18n.t<string>('campaignSummary.labels.videoAdViewObjectiveDefault');
    }
  }

  getDayPartValue (dayPart) {
    let dayPartValue = _.omitBy(_.omit(dayPart, 'enabled'), _.isEmpty);
    return Object.keys(dayPartValue).map(day => {
      return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t<string>('daypart.labels.hourUnit')}: ${dayPartValue[day].join(', ')}`;
    }).join('\r\n');
  }

  getSessionCappaingCountValue (maxFrequency: number, intervalDays: number) {
    return i18n.t<string>('adSetSetupFlow.mainStep.labels.frequencyControl', {
      event: i18n.t<string>('adSetSetupFlow.mainStep.labels.impression'),
      eventPlural: +(maxFrequency) > 1 ? 's' : '',
      interval_days: intervalDays,
      max_frequency: maxFrequency,
      unit: i18n.t<string>('common.units.day'),
      unitPlural: +(intervalDays) > 1 ? 's' : ''
    });
  }

  getCampaignPlanTypeValue (campaignPlanType) {
    return i18n.t<string>(`campaign.labels.${campaignPlanType}`);
  }

  getAdTypeValue (adType) {
    const key = `campaignSummary.labels.adType${upperFirst(camelCase(adType))}`;
    if (!i18n.exists(key)) {
      return adType;
    }

    return i18n.t<string>(key);
  }

  getDeliverTypeValue (deliverType) {
    switch (deliverType) {
      case DeliverType.ACCELERATED:
        return i18n.t<string>('campaignSummary.labels.accelerated');
      default:
        return i18n.t<string>('campaignSummary.labels.standard');
    }
  }

  getTags (tags) {
    if (!tags || tags.length === 0) {
      return i18n.t<string>('summary.titles.noData');
    }

    return tags.join(',');
  }

  getCreativeDeliverTypeValue (creativeDeliverType) {
    switch (creativeDeliverType) {
      case CreativeDeliverType.OPTIMIZE:
        return i18n.t<string>('campaignSummary.labels.creativeOptimizeDelivery');
      default:
        return i18n.t<string>('campaignSummary.labels.creativeUniformDelivery');
    }
  }

  getOutdoorDeliverTypeValue (outdoorDeliverType: OutdoorDeliverType) {
    const key = OutdoorDeliverType[outdoorDeliverType];
    return i18n.t<string>(`campaign.labels.outdoorDeliverType.${key.toLowerCase()}`);
  }

  async getLegacyEstimateData () {
    if (!this.showLegacyEstimation) {
      return;
    }
    const campaign = this.flowModel.state.campaign;
    this.updateState(true);
    try {
      this.legacyEstimateData = await this.campaignManager.getLegacyEstimateData(campaign.basic, campaign.limitations, this.order.campaignBidPrice);
    } catch (e) {}
    this.updateState(false);
  }

  submit = async () => {
    if (this.flowModel.type === 'edit') {
      await this.updateCampaign(this.flowModel.state.campaign, this.flowModel.l1Object);
    } else if (this.flowModel.type === 'split') {
      await this.splitCampaign(this.flowModel.state.campaign, this.flowModel.campaignId, this.flowModel.l1Object);
    } else {
      await this.createCampaign(this.flowModel.state.campaign, this.flowModel.l1Object);
    }
  }

  splitCampaign = async (campaign: any, campaignId, l1Object: L1Object) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId');
      await this.campaignManager.splitCampaign(campaign, campaignId, l1ObjectId);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.redirectToLastPage();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  createCampaign = async (campaign: any, l1Object: L1Object) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId');
      if (this.flowModel.isPmp && this.creativeSummaryStepModel) {
        const creative = await this.creativeSummaryStepModel.getJsonToSubmit();
        const pmpFormModel = this.flowModel.campaignBasicFormModel as PmpFormModel;
        await this.campaignManager.createPmpCampaign(campaign, l1ObjectId, pmpFormModel.pmp!.id, creative);
      } else if (this.creativeSummaryStepModel) {
        const creative = await this.creativeSummaryStepModel.getJsonToSubmit();
        await this.campaignManager.createCampaignWithCreative(campaign, l1ObjectId, creative);
      } else {
        await this.campaignManager.createCampaign(campaign, l1ObjectId);
      }
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.redirectToLastPage();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  updateCampaign = async (campaign: any, l1Object: L1Object) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId');
      await this.campaignManager.updateCampaign(campaign, l1ObjectId);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.redirectToLastPage();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  redirectToLastPage = () => {
    const order = this.flowModel.order;
    const l1Object = this.flowModel.l1Object;
    const l1ObjectId = _.get(l1Object, 'l1ObjectId');
    const redirectPath = l1ObjectId ?
        `/orders/${order.orderNumber}/campaign-groups/${l1ObjectId}` :
        `/orders/${order.orderNumber}`;
    this.flowModel.setFinishedRedirectPath(redirectPath);
  }

  onShowAddSaveTargetingModal = (showModal: boolean) => {
    this.showAddSaveTargetingModal = showModal;
    this.updateState(false);
  }

  initCreativeSummaryModel (): void {
    if (!this.creativeSummaryStepModel) {
      return;
    }
    this.creativeSummaryDataHanlder = this.creativeSummaryStepModel.event.add(model => {
      this.creativeSummaryStepState = model.state;
      const {
        basicSummaryData,
        mediaSummaryData
      } = this.creativeSummaryStepState;
      if (basicSummaryData) {
        basicSummaryData.goStep = () => this.goStep(CreateRtbCampaignWithCreativeSetupFlowStep.SETUP_CREATIVE);
      }
      if (mediaSummaryData) {
        mediaSummaryData.goStep = () => this.goStep(CreateRtbCampaignWithCreativeSetupFlowStep.SETUP_CREATIVE);
      }
      this.event.fireEvent(this);
    });
    this.creativeSummaryStepModel.initSummaryData();
  }

  unmount (eventHandler?: number) {
    eventHandler && this.event.remove(eventHandler);

    if (!this.creativeSummaryStepModel || !this.creativeSummaryDataHanlder) {
      return;
    }
    this.creativeSummaryStepModel.event.remove(this.creativeSummaryDataHanlder);
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class RtbCampaignDraftSummaryStepModel extends DefaultRtbCampaignSummaryStepModel {

  constructor (
    private draftId: string,
    flowModel: RtbCampaignSetupFlowPageModel,
    subStepIndex: number,
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void,
    goSubStep: (subStepIndex: number) => void,
    private draftManager: DraftManager = new RtbCampaignDraftManager()
  ) {
    super(flowModel, subStepIndex, goLast, goStep, goSubStep);
  }

  submit = async () => {
    const campaign = this.flowModel.state.campaign;
    this.updateState(true);
    try {
      const {
        includeBinding,
        result
      } = await this.draftManager.publishDraft(this.draftId, this.campaignManager.prepareCreateCampaignPayload(campaign));
      if (result === 'SUCCESS') {
        if (includeBinding) {
          toast.success(i18n.t<string>('campaignSetupFlow.messages.publishL2L3Success'));
        } else {
          toast.success(i18n.t<string>('campaignSetupFlow.messages.publishL2Success'));
        }
        this.redirectToLastPage();
      }
      if (result === 'PARTIAL_SUCCESS') {
        this.showPublishBindingFailed = true;
        this.updateState(false);
      } else if (result === 'FAIL') {
        toast.error(i18n.t<string>('campaignSetupFlow.messages.publishL2L3Failed'));
        this.updateState(false);
      }
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }
}

export class MessageRtbCampaignSummaryStepModel extends DefaultRtbCampaignSummaryStepModel {

  getSetUpSummaryData () {
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const priceModelRelatedSummaryData = this.getPriceModelRelatedSummaryData(campaignSetUpData.priceModel);
    return {
      title: i18n.t<string>('campaignSummary.titles.basicSetting'),
      summaryData: _.omitBy({
        general: {
          title: i18n.t<string>('campaignSummary.titles.generalInformation'),
          content: _.compact(_.concat([
            {
              label: i18n.t<string>('campaignSummary.labels.orderName'),
              value: this.flowModel.order.projectName
            },
            {
              label: i18n.t<string>('campaignSummary.labels.campaignName'),
              value: campaignSetUpData.name
            },
            {
              label: i18n.t<string>('campaignSummary.labels.deliverDate'),
              value: campaignSetUpData.startDate
            },
            {
              label: i18n.t<string>('campaignSummary.labels.messages'),
              value: campaignSetUpData.messages
            },
            {
              label: i18n.t<string>('campaignSummary.labels.pricingModel'),
              value: this.getCampaignPlanTypeValue(campaignSetUpData.priceModel)
            },
            {
              label: i18n.t<string>('campaignSummary.labels.tags'),
              value: this.getTags(campaignSetUpData.tags)
            }
          ], priceModelRelatedSummaryData))
        },
        optimizeSetting: {
          title: i18n.t<string>('adSetSetupFlow.mainStep.fieldset.optimizationTitle'),
          content: _.compact([
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal'),
              value: i18n.t<string>(`optimizationGoal.${campaignSetUpData.optimize.toLowerCase()}`)
            },
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent'),
              value: i18n.t<string>(`rtbCampaign.billingEvent.${campaignSetUpData.priceModel}`)
            }
          ])
        }
      }, _.isUndefined),
      goStep: () => this.goStep(this.campaignSetupStepIndex, CampaignSetupTab.BASIC)
    };
  }

  getPriceModelRelatedSummaryData (priceModel) {
    const currency = this.flowModel.order.currency;
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const needShowOrderPrice = priceModel !== RtbCampaignPlanType.RS && priceModel !== RtbCampaignPlanType.DCPM;
    const needShowBudget = !_.get(this.flowModel.l1Object, 'autoOptimise', false);
    return _.compact([
      (needShowOrderPrice && campaignSetUpData.orderPrice) && {
        label: i18n.t<string>('campaignSummary.labels.orderPrice', { model: this.getCampaignPlanTypeValue(priceModel) }),
        value: `${currency} ${campaignSetUpData.orderPrice}`
      },
      (needShowBudget) && {
        label: i18n.t<string>('campaignSummary.labels.campaignBudget'),
        value: `${currency} ${campaignSetUpData.budget}`
      }
    ]);
  }
}

export class MessageRtbCampaignDraftSummaryStepModel extends MessageRtbCampaignSummaryStepModel {

  constructor (
    private draftId: string,
    flowModel: RtbCampaignSetupFlowPageModel,
    subStepIndex: number,
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex?: number) => void,
    goSubStep: (subStepIndex: number) => void,
    private draftManager: DraftManager = new RtbCampaignDraftManager()
  ) {
    super(flowModel, subStepIndex, goLast, goStep, goSubStep);
  }

  submit = async () => {
    const campaign = this.flowModel.state.campaign;
    this.updateState(true);
    try {
      const {
        includeBinding,
        result
      } = await this.draftManager.publishDraft(this.draftId, this.campaignManager.prepareCreateCampaignPayload(campaign));
      if (result === 'SUCCESS') {
        if (includeBinding) {
          toast.success(i18n.t<string>('campaignSetupFlow.messages.publishL2L3Success'));
        } else {
          toast.success(i18n.t<string>('campaignSetupFlow.messages.publishL2Success'));
        }
        this.redirectToLastPage();
      }
      if (result === 'PARTIAL_SUCCESS') {
        this.showPublishBindingFailed = true;
        this.updateState(false);
      } else if (result === 'FAIL') {
        toast.error(i18n.t<string>('campaignSetupFlow.messages.publishL2L3Failed'));
        this.updateState(false);
      }
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }
}
