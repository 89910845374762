import React from 'react';
import styles from './CreativeList.module.scss';
import i18n from 'i18n';
import { Status } from 'components/Status/Status';
import { CampaignBannerMapState, CreativeStatus, CreativeType } from 'core/creative/Creative';
import { CreativePreview } from 'components/CreativePreview/CreativePreview';
import { DefaultCreativePreviewModel } from 'components/CreativePreview/CreativePreviewModel';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faChartArea, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { addOnEnabled, hasFuncs, notSelfServeAdObject } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { numberWithCommas } from 'utils/StringUtil';
import moment from 'moment';
import { CreativeReviews } from 'components/CreativeReview/CreativeReviews';
import { CREATIVES } from 'core/agency/AddonFeature';
import { ColumnDescription, ColumnFormatter } from 'react-bootstrap-table-next';
import { PermissionAware } from 'core';
import { CreativeListRow } from './CreativeListRow';
import { L1Object } from 'core/l1Object/L1Object';

const nameHeaderFormatter = (
  creativeListLength: number,
  selectedCreativesLength: number,
  modifyPermissionAware: PermissionAware | undefined,
  handleOnSelectAll: () => void,
  column: ColumnDescription
): React.ReactNode => {
  const permissionAware = modifyPermissionAware
    ? modifyPermissionAware
    : hasFuncs(Permission.CREATIVE_WRITE).or(
        hasFuncs(Permission.CAMPAIGN_WRITE)
      );
  return (
    <div className={styles.idHeader}>
      <div className={styles.selectCheckBox}>
        <PermissionChecker permissionAware={permissionAware}>
          <input
            type='checkbox'
            checked={
              creativeListLength - 1 === selectedCreativesLength &&
              creativeListLength > 1
            }
            ref={el =>
              el &&
              (el.indeterminate =
                creativeListLength - 1 !== selectedCreativesLength &&
                selectedCreativesLength > 0)
            }
            onChange={handleOnSelectAll}
            id={'creativeListInput0'}
          />
          <label htmlFor='creativeListInput0' />
        </PermissionChecker>
      </div>
      {i18n.t<string>(column.text)}
    </div>
  );
};

type CreatvieIDFormatterExtraData = {
  handleOnSelect: (id: number) => void,
  modifyPermissionAware: PermissionAware
};

const creatvieIDFormatter: ColumnFormatter<CreativeListRow, CreatvieIDFormatterExtraData, number> = (
  cell: number,
  row: CreativeListRow,
  _1,
  formatExtraData?: CreatvieIDFormatterExtraData
) => {
  const handleOnSelect = (e) => {
    formatExtraData && formatExtraData.handleOnSelect(row.id);
  };
  const permissionAware = _.get(formatExtraData, 'modifyPermissionAware',
    hasFuncs(Permission.CREATIVE_WRITE).or(hasFuncs(Permission.CAMPAIGN_WRITE)));
  return (
  cell ?
    <div className={styles.creativeCell}>
      <div className={styles.selectCheckBox}>
        <PermissionChecker permissionAware={permissionAware}>
          <input
            type='checkbox'
            checked={row.selected}
            onChange={handleOnSelect}
            id={`creativeListInput${row.id}`}
          />
          <label htmlFor={`creativeListInput${row.id}`}/>
        </PermissionChecker>
      </div>
      <div className={styles.info}>
        <div className={styles.creativeName}>
          {row.name}
        </div>
        { row.rtbCreativeId &&
          <div>
            {`ID: ${row.rtbCreativeId}`}
          </div>
        }
        </div>
    </div>
  : <div/>
  );
};

const percentageFormatter = (cell: number): React.ReactNode => {
  return (
    <div className={styles.creativeCell}>
      {(cell * 100).toFixed(2) + '%'}
    </div>
  );
};

const numberFormatter = (cell: number): React.ReactNode => {
  return (
    <div className={styles.creativeCell}>
      {numberWithCommas(cell)}
    </div>
  );
};

const stateFormatter = (bindingState: CampaignBannerMapState, creative: CreativeListRow): React.ReactNode => {
  const active = bindingState === CampaignBannerMapState.ENABLE;
  const waiting = bindingState === CampaignBannerMapState.WAITING_FB_UPDATE;

  let color;
  let label;
  label = i18n.t<string>(`creative.status.${active ? 'enable' : 'disable'}`);
  color = active ? 'success' : 'black';
  if (waiting) {
    color = 'theme1';
    label = creative.isActiveBinding ?
    i18n.t<string>(`creative.status.waitingActivate`) :
    i18n.t<string>(`creative.status.waitingDeactivate`);
  }

  return (
    <>
      <Status
        color={color}
        label={label}
      />
      {(creative.enableStartTime || creative.enableEndTime) &&
        <div className={styles.duration}>
          {creative.enableStartTime && <div>{`${i18n.t<string>('creativeList.labels.enableStartTime')}: ${creative.enableStartTime}`}</div>}
          {creative.enableEndTime && <div>{`${i18n.t<string>('creativeList.labels.enableEndTime')}: ${creative.enableEndTime}`}</div>}
        </div>
      }
    </>
  );
};

const layoutFormatter = (value: string[]) => {
  const layout = value[0].length !== 0 ? i18n.t<string>(`adneon.layouts.${_.camelCase(value[0])}`) : '';
  return (
    <div className={styles.creativeCell}>
      <div className={styles.value}>
        {layout}
      </div>
    </div>
  );
};

const productSetFormatter = (
  cell:
    | {
      productSet: string;
      productSetId: string;
    }
    | undefined,
  creativeData: CreativeListRow
): React.ReactNode => {
  const productSet = _.get(creativeData, 'productSet', {});
  const productSetName = _.get(productSet, 'productSet', '');
  const productSetId = _.get(productSet, 'productSetId', '');

  return cell ? (
    <div className={styles.creativeCell}>
      <div className={styles.info}>
        <div className={styles.creativeName}>{productSetName}</div>
        <div>{`ID: ${productSetId}`}</div>
      </div>
    </div>
  ) : (
    <div />
  );
};

const getCreativeDeliveryStatus = (effectiveStatus: string, creative: CreativeListRow) => {
  const startTime = creative.enableStartTime;
  const endTime = creative.enableEndTime;
  const isNowBeforeEnableStartTime = startTime && moment() < moment(startTime);
  const isNowAfterEnableEndTime = endTime && moment() > moment(endTime);
  const inTime = !isNowBeforeEnableStartTime && !isNowAfterEnableEndTime;
  const active = inTime && effectiveStatus === 'ACTIVE';
  const waiting = inTime && effectiveStatus === 'IN_PROCESS';
  const isProcessing = creative.status === CreativeStatus.PROCESSING;
  let color;
  let label;
  let extraInfo;
  if (isProcessing) {
    label = i18n.t<string>('creative.status.processing');
    color = 'warning';
  } else if (effectiveStatus === 'WITH_ISSUES') {
    label = i18n.t<string>('campaignList.labels.withIssues');
    color = 'danger';
    extraInfo = _.get(creative.issuesInfo, 'error_summary');
  } else if (isNowAfterEnableEndTime) {
    label = i18n.t<string>(`creativeList.labels.afterEndTime`);
    color = 'light';
  } else if (isNowBeforeEnableStartTime) {
    label = i18n.t<string>(`creativeList.labels.beforeStartTime`);
    color = 'white';
  } else if (active) {
    label = i18n.t<string>('creative.status.enable');
    color = 'theme1';
  } else if (waiting) {
    color = 'whiteTheme4';
    label = creative.isActiveBinding ?
      i18n.t<string>(`creative.status.waitingActivate`) :
      i18n.t<string>(`creative.status.waitingDeactivate`);
  } else {
    label = i18n.t<string>('creative.status.disable');
    color = 'black';
    extraInfo = _.startCase(_.lowerCase(effectiveStatus));
  }
  return {
    label,
    color,
    extraInfo
  };
};

const deliveryFormatter = (effectiveStatus: string, row: CreativeListRow, _1, formatExtraData): React.ReactNode => {
  if (formatExtraData.isDraft) {
    const renderExtraInfo = () => i18n.t<string>('campaignList.labels.draftStateHint');
    return (
      <Status
        label={i18n.t<string>('creative.status.disable')}
        color={'black'}
        renderExtraInfo={renderExtraInfo}
      />
    );
  }

  const {
    label,
    color,
    extraInfo
  } = getCreativeDeliveryStatus(effectiveStatus, row);

  return (
    <Status
      label={label}
      color={color}
      renderExtraInfo={extraInfo ? () => extraInfo : undefined}
    />
  );
};

const typeAndSizeFormatter = (_1, row: CreativeListRow): React.ReactNode => {
  return (
    <div className={styles.creativeCell}>
      <div className={styles.info}>
      {row.creativeType ?
        <>
          <div>
            {i18n.t<string>('agency.addonItem.creatives.option_' + _.camelCase(CreativeType[row.creativeType]))}
          </div>
          <div>
            {row.size}
          </div>
        </> :
        <div>
          {i18n.t<string>('creativeType.custom')}
        </div>
      }
      </div>
    </div>

  );
};

const approvalFormatter = (value, creativeData: CreativeListRow, _2, formatExtraData) => {
  if (formatExtraData.isDraft) {
    return 'N/A';
  }

  if (_.isEmpty(value) || !creativeData) {
    return <div />;
  }

  return (
    <CreativeReviews
      creativeData={creativeData}
      reviewCreative={formatExtraData.reviewCreative}
    />
  );
};

const resultsFormatter = (value: number | undefined, _1, _2, formatExtraData) => {
  const l1Object = formatExtraData.l1Object;
  const objective = _.get(l1Object, 'objective');
  return (
    <div>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {objective}
      </div>
    </div>
  );
};

const uuFormatter = (value: number | undefined) => {
  return value !== undefined ? numberFormatter(value) : i18n.t<string>('common.labels.noData');
};

const floatingEditBtnsFormatter = (
  _1,
  creative: CreativeListRow,
  _2,
  formatExtraData?: {
    goReportPage: (creative: CreativeListRow) => void;
    getEditPath: (creativeId: number) => string;
    l1Object: L1Object;
  }
): React.ReactNode => {
  const goReportPage = () => formatExtraData!.goReportPage(creative);
  const definedCreativeAddons = Object.values(CREATIVES).map(item =>
    item.toString()
  );
  const needCheckAddon = definedCreativeAddons.includes(
    `option_${creative.creativeType}`
  );
  const editPermissionAware = needCheckAddon
    ? hasFuncs(Permission.CREATIVE_WRITE)
        .and(addOnEnabled(`option_${creative.creativeType}`))
        .and(notSelfServeAdObject(formatExtraData!.l1Object))
    : hasFuncs(Permission.CREATIVE_WRITE).and(
        notSelfServeAdObject(formatExtraData!.l1Object)
      );
  return (
    <TableRowToolBar className={styles.creativeFloatingEditArea}>
      {!creative.creativeType ? (
        <IconWithTooltip
          icon={faPencilAlt}
          disabled={true}
          tooltipProps={{
            id: `campaignCreativeListReportTip-${creative.id}`,
            tooltip: i18n.t<string>(
              'campaignCreativeList.labels.cannotEditCustomCreativeHint'
            )
          }}
        />
      ) : (
        <PermissionChecker permissionAware={editPermissionAware}>
          <Link to={formatExtraData!.getEditPath(creative.rtbCreativeId)}>
            <IconWithTooltip
              icon={faPencilAlt}
              tooltipProps={{
                id: `campaignCreativeListReportTip-${creative.id}`,
                tooltip: i18n.t<string>('campaignCreativeList.labels.editHint')
              }}
            />
          </Link>
        </PermissionChecker>
      )}
      <IconWithTooltip
        icon={faChartArea}
        onClick={goReportPage}
        tooltipProps={{
          id: `campaignCreativeListReportTip-${creative.id}`,
          tooltip: i18n.t<string>('campaignCreativeList.labels.reportHint')
        }}
      />
    </TableRowToolBar>
  );
};

const creativePreviewFormatter = (_1, row: CreativeListRow): React.ReactNode => {
  return (
    <div className={styles.creativeReview}>
      <CreativePreview model={new DefaultCreativePreviewModel(row)}/>
    </div>
  );
};

const formatters = {
  nameHeaderFormatter,
  creatvieIDFormatter,
  percentageFormatter,
  numberFormatter,
  layoutFormatter,
  productSetFormatter,
  stateFormatter,
  deliveryFormatter,
  typeAndSizeFormatter,
  approvalFormatter,
  resultsFormatter,
  uuFormatter,
  floatingEditBtnsFormatter,
  creativePreviewFormatter
};

export type CreativeListFormatters = typeof formatters;

export default formatters;
