import { AdLogo } from 'core/adLogo/AdLogo';

export enum OutdoorType {
  VIDEO = 1,
  IMAGE_AUDIO = 2,
  VIDEO_IMAGE = 3,
  HTML5 = 4
}

export type CreativeApprovalStatus = {
  [adxName: string]: {
    state: number;
    attr: number[];
    udt: string;
  };
};

export type NativeBanner = {
  creativeId: number;
  creativeReview: CreativeApprovalStatus;
  width: number;
  height: number;
  htmlUrl: string;
};

export type NativeBannerMap = { [key: `${number}x${number}`]: NativeBanner };

export type CreativeOfCampaign = {
  rtbCreativeId: any,
  id: number,
  name: string,
  status: number,
  vtr: number,
  vctr: number,
  vimps: number,
  view: number,
  ctr: number,
  imps: number,
  clicks: number,
  size: string,
  creativeType: CreativeType,
  enableStartTime?: string,
  enableEndTime?: string,
  approvalStatus: CreativeApprovalStatus,
  bannerImageUrl: string,
  creativeValue: any,
  createTime: string,
  landingPageUrl: string,
  isActiveBinding: boolean,
  tenmaxCategory: string,
  origTenmaxCategory: string | null,
  shortcutUrl: string,
  bindingState: CampaignBannerMapState,
  nativeBanner?: NativeBannerMap,
  containFBInternalUrl?: boolean,
  effectiveStatus?: string,
  issuesInfo?: string,
  adReviewFeedback?: string,
  l3ChannelId: string,
  report?: any,
  bannerExtra?: { [key: string]: string | number | boolean | object },
  previewLink?: string,
  ppsLayoutId?: string;
  retail?: string,
  productSet?: {
    productSet: string,
    productSetId: string
  },
  subLayout?: string;
  videoChildren?: VideoChildMap;
};

export type Creative = {
  basic: CreativeBasic,
  limitations: { [type: string]: Limitation[] };
};

export type VideoChildMap = {
  [deviceType: string]: {
    creativeId: number;
    videoUrl?: string;
    approvalStatus: CreativeApprovalStatus;
  };
};

export type CreativeBasic = {
  advertiserId: number,
  agencyId: number,
  bannerUrl: string,
  creativeId: number,
  creativeType: CreativeType,
  creativeValues: any,
  landingPageUrl: string,
  name: string,
  tenmaxCategory: string,
  bannerExtra?: { [key: string]: string | number | boolean | object },
  enableNativeBanner?: boolean,
  nativeBanner?: NativeBannerMap,
  bannerImageUrl?: string,
  outdoorType?: OutdoorType,
  duration?: number,
  enableStartTime?: string,
  enableEndTime?: string,
  retail?: string,
  productSet?: {
    productSet: string,
    productSetId: string
  },
  ppsLayoutId?: string,
  subLayout?: string,
  adLogo?: AdLogo
};

export type BindingCampaign = {
  id: number,
  name: string,
  orderNumber: string,
  active: boolean,
  isActive: boolean,
  canUnbind: boolean
};

export enum CampaignBannerMapState {
  DEFAULT,
  ENABLE,
  DISABLE,
  WAITING_FB_UPDATE,
  BEFORE_START_TIME,
  AFTER_END_TIME,
  IN_PROGRESS
}

export type CreativeListRecord = {
  advertiserId: number,
  approvalStatus: CreativeApprovalStatus,
  bannerImageUrl: string | null,
  bannerUrl: string,
  bindingCampaign: BindingCampaign[],
  bindingCount: { enable: number, disable: number },
  createTime: string,
  creativeId: number,
  creativeState: CreativeStatus,
  creativeType: CreativeType,
  creativeValues: { [key: string]: string | number | boolean | object },
  limitations: { [type: string]: Limitation[] },
  name: string,
  tenmaxCategory: string,
  origTenmaxCategory: string | null,
  bannerExtra?: { [key: string]: string | number | boolean | object },
  enableNativeBanner: boolean,
  nativeBanner?: NativeBannerMap,
  ppsLayoutId?: string,
  outdoorType?: OutdoorType,
  duration?: number,
  enableStartTime?: string,
  enableEndTime?: string,
  productSet?: {
    productSet: string,
    productSetId: string
  },
  retail?: string,
  subLayout?: string,
  videoChildren?: VideoChildMap
};

type Limitation = {
  op: string;
  value: Array<SelectOptions & { isGroup?: boolean }> | string | number;
  type: string;
  isGroup?: boolean;
};

export enum VideoPlacement {
  IN_STREAM = 1,
  OUT_STREAM,
  UNLIMIT
}

export enum CreativeType {
  IMAGE = 1,
  HTML5,
  NATIVE,
  VIDEO,
  // DYNAMIC,
  THIRDPARTY = 8,
  COMBO = 10,
  EDIMAX,
  ONE_FOR_ALL,
  ONE_FOR_ALL_DISPLAY,
  ONE_FOR_ALL_DISPLAY_IOS,
  ONE_FOR_ALL_DISPLAY_ANDROID,
  ONE_FOR_ALL_VIDEO,
  ONE_FOR_ALL_VIDEO_IOS,
  ONE_FOR_ALL_VIDEO_ANDROID,
  // AD_NEON,
  RETAIL_RICH_MEDIA_PRODUCT = 20,
  RETAIL_NATIVE_PRODUCT,
  PILOT_TV,
  CTV,
  CUSTOM_RECTANGLE,
  CUSTOM_BOTTOM,
  PIC_SHORT,
  PIC_LONG,
  FB_OTHERS = 99
}

export type CreativeValue = {
  w?: number;
  h?: number;
  imageUrl?: number;
  title?: string;
  desc?: string;
  img?: ImageData;
  img_l?: ImageData;
  img_s?: ImageData;
  icon?: ImageData;
  icon_l?: ImageData;
  icon_s?: ImageData;
  sponsored?: Sponsored;
};

export type ImageData = {
  url: string;
  w: number;
  h: number;
};

export type Sponsored = {
  name: string;
  link: string;
};

export enum CreativeStatus {
  'DEACTIVE',
  'ACTIVE',
  'PROCESSING'
}

export enum ApprovalStatus {
  'NEW',
  'PENDING',
  'APPROVED',
  'DENIED',
  'FIXED',
  'INCOMPLETED',
  'DELETED'
}

export enum ADX {
  asiamax = 'AsiaMax',
  doubleclick = 'DoubleClick',
  verizon = 'Verizon',
  facebook = 'Facebook'
}

export enum CALL_TO_ACTION {
  LEARN_MORE = 'LEARN_MORE',
  SHOP_NOW = 'SHOP_NOW',
  USE_APP = 'USE_APP',
  GET_OFFER = 'GET_OFFER'
}

export enum RETAILMAX_LAYOUT_TYPE {
  IMAGE = 'pps_medium_rectangle_image_only_shared_version',
  VIDEO = 'pps_medium_rectangle_video_only_shared_version'
}

export enum ADNEON_CREATIVE_LAYOUT {
  DESKTOP_SLIDE_PRODUCTSET = 'desktop-slide-productset',
  MOBILE_LIST_ITEM_PRODUCTSET = 'mobile-list-item-productset',
  DESKTOP_LIST_ITEM_PRODUCTSET = 'desktop-list-item-productset'
}

export enum CREATIVE_LOGO_POSITION {
  LEFT = 'left',
  MIDDLE = 'middle',
  RIGHT = 'right'
}

export enum CREATIVE_DISPLAYSIZE {
  DEFAULT = 'default',
  LARGE = 'large'
}

export enum THIRD_PARTY_TYPE {
  NORMAL = 'NORMAL',
  ADNEON = 'ADNEON'
}

export type CreativeBasicFields = {
  creativeId?: number;
  advertiserId: number;
  creativeType: CreativeType;
  tenmaxCategory: string;
  name: string;
  enableStartTime?: string | undefined;
  enableEndTime?: string | undefined;
};
