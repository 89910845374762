import React, { useEffect, useState } from 'react';
import 'rc-slider/assets/index.css';
import { FormConfig } from 'components/common/form/FormConfig';
import { FormContent } from 'components/common/form/Form';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useVideoOutdoorFields } from './hooks/useVideoOutdoorFields';
import { useImageAudioOutdoorFields } from './hooks/useImageAudioOutdoorFields';
import { useVideoImageOutdoorFields } from './hooks/useVideoImageOutdoorFields';
import { useHtmlOutdoorFields } from './hooks/useHtmlOutdoorFields';
import { useOutdoorBasicFields } from './hooks/useOutdoorBasicFields';
import { OutdoorFormModel, OutdoorFormProps } from './OutdoorFormModel';
import i18n from 'i18n';
import { useOutdoorLineFields } from './hooks/useOutdoorLineFields';

export const OutdoorForm = <T extends OutdoorFormModel<T>>(props: OutdoorFormProps<T>) => {

  const [formConfig, setFormConfig] = useState<FormConfig>(
    props.getInitFormConfig()
  );

  const {
    forPmp,
    adFormat,
    getOutdoorTypeOptions,
    getDefaultDuration
  } = props.model;

  const { loading, fields: outdoorBasicFields } = useOutdoorBasicFields(
    forPmp,
    adFormat,
    getDefaultDuration,
    getOutdoorTypeOptions
  );

  const videoFormFields = useVideoOutdoorFields(props.model);
  const imageAudioFormFields = useImageAudioOutdoorFields(props.model);
  const videoImageFormFields = useVideoImageOutdoorFields(props.model);
  const htmlFormFields = useHtmlOutdoorFields(props.model);
  const lineFormFields = useOutdoorLineFields(props.model);

  useEffect(() => {
    setFormConfig(
      new FormConfig.Builder()
        .addSection(
          new FormConfig.SectionBuilder([
            ...props.basicFields,
            ...outdoorBasicFields,
            ...videoFormFields,
            ...imageAudioFormFields,
            ...videoImageFormFields,
            ...htmlFormFields,
            ...lineFormFields
          ])
          .withTitle(
            i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo')
          )
          .build()
        )
        .build()
    );
  }, [
    props.basicFields,
    outdoorBasicFields,
    videoFormFields,
    imageAudioFormFields,
    videoImageFormFields,
    htmlFormFields,
    lineFormFields
  ]);

  return (
    <>
      {loading && <LoadingIndicator />}
      <FormContent formConfig={formConfig} />
    </>
  );
};
