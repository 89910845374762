import { CreativeType, OutdoorType, RETAILMAX_LAYOUT_TYPE, VideoPlacement } from 'core/creative/Creative';
import _ from 'lodash';
import i18n from 'i18n';
import { NativeMediaSummary } from './NativeMediaSummary';
import { VideoMediaSummary } from './VideoMediaSummary';
import { CreativeFormBasicData } from '../FormContent/FormContentModel';
import { ImageMediaSummary } from './ImageMediaSummary';
import { PPSMediaSummary } from './PPSMediaSummary';
import { OutdoorMediaSummary } from './OutdoorMediaSummary';
import { CloudStorageManager, DefaultCloudStorageManager } from 'core/cloudStorage/CloudStorageManager';
import { ComboMediaSummary } from './ComboMediaSummary';
import { OneForAllDisplayMediaSummary } from './OneForAllDisplayMediaSummary';
import { OneForAllVideoMediaSummary } from './OneForAllVideoMediaSummary';
import { renderFBPage } from './SummaryInfoRenderFunctions';
import React from 'react';
import { ImageMultipleMediaSummary } from './ImageMultipleMediaSummary';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { computeChecksumMd5 } from 'utils/Md5Utils';
import { AdNeonMediaSummary } from './AdNeonMediaSummary';
import { RetailProductMediaSummary } from './RetailProductMediaSummary';
import config from 'config';
import { AdLogoType } from 'core/adLogo/AdLogo';
import { CustomLayoutManager, DefaultCustomLayoutManager } from 'core/customLayout/CustomLayoutManager';
import { CustomLayoutMediaSummary } from './CustomLayoutMediaSummary';
import { MediaSummaryData } from 'components/FlowSummarySection/FlowSummarySection';
import { LineBeaconServiceType } from 'core/advertiser/Advertiser';

export interface CreativeSummaryModel {
  getCreativeBasicSummaryData: (
    getUploadedFileData?: (file: File) => Promise<any>,
    addUploadedFilesData?: (file: File, data: any) => Promise<void>
  ) => Promise<any>;
  getMediaSummaryComponent: () => React.FC<{ mediaSummary: MediaSummaryData }>;
  getMediaSummary: () => MediaSummaryData;
  getBasicSubmitData: () => Promise<FormData>;
  getJsonSubmitData: () => Promise<Record<string, any>>;
  getSubmitAlertMessage: () => string | undefined;
}

const getMediaSummaryData = (image) => {
  const file = _.get(image, 'file');
  const url = _.get(image, 'url');
  if (file || url) {
    return {
      file,
      url
    };
  }
};

abstract class DefaultCreativeSummaryModel implements CreativeSummaryModel {

  constructor (
    protected creativeBasicFormData: CreativeFormBasicData,
    protected cloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager(),
    protected creativeManager: CreativeManager = new DefaultCreativeManager()
  ) {}

  getI18nOfValueByKey (key, value) {
    switch (key) {
      case 'tenmaxCategory':
        return i18n.t<string>(`tenmaxCategory.labels.${value}`);
      case 'creativeType':
        return i18n.t<string>(`creativeType.${_.camelCase(CreativeType[value])}`);
      case 'enableNativeBanner':
        return i18n.t<string>(`common.labels.${value ? 'yes' : 'no'}`);
      default:
        return value;
    }
  }

  transferObjectToSummaryData (data, keyPrefix = '') {
    if (!data) {
      return [];
    }
    return _.flattenDeep(Object.keys(data).map(key => {
      const currentKey = _.camelCase(`${keyPrefix}-${key}`);
      if (typeof data[key] === 'object' && !React.isValidElement(data[key])) {
        return this.transferObjectToSummaryData(data[key], currentKey);
      }
      const i18nKey = `creativeSetupFlow.labels.${currentKey}`;
      return {
        label: i18n.exists(i18nKey) ? i18n.t<string>(`creativeSetupFlow.labels.${currentKey}`) : currentKey,
        value: this.getI18nOfValueByKey(currentKey, data[key])
      };
    }));
  }

  async getCreativeBasicSummaryData () {
    const omitProps = ['advertiserId', 'medias', 'typeProperties', 'bannerExtra', 'nativeBanner', 'bannerImageUrl', 'ppsLayoutId', 'adLogo'];
    const basic = _.omitBy(_.omit(this.creativeBasicFormData, omitProps), _.isUndefined);
    return _.uniqBy(_.compact([
      ...this.transferObjectToSummaryData(basic),
      ...this.transferObjectToSummaryData(this.getTypePropertiesSummary())
    ]), 'label');
  }

  setBasicFormData (formData, omitKeys: string[]) {
    const basic = _.omit(this.creativeBasicFormData, [...omitKeys, 'adLogo']);
    for (let key in basic) {
      if (basic[key] !== undefined && basic[key] !== null) {
        formData.append(`creative.${key}`, basic[key]);
      }
    }
    const adLogo = _.get(this.creativeBasicFormData, 'adLogo');
    if (adLogo) {
      const adLogoData = this.getAdLogoData(adLogo);
      Object.keys(adLogoData).forEach(key => {
        formData.append(`creative.adLogo[${key}]`, adLogoData[key]);
      });
    }
  }

  getSubmitAlertMessage (): string | undefined {
    return undefined;
  }

  abstract getMediaSummaryComponent ();
  abstract getTypePropertiesSummary ();
  abstract getMediaSummary ();
  abstract getBasicSubmitData (): Promise<FormData>;

  async getJsonSubmitData () {
    const formData = await this.getBasicSubmitData();

    let result: Record<string, any> = {};
    formData.forEach((value, key) => {
      const parsedKey = key.replace(/\[(\d+)\]/g, '.$1'); // 將 key[0] 轉換成 key.0
      _.set(result, parsedKey, value);
    });

    return result.creative;
  }

  getAdLogoData (adLogoData) {
    if (adLogoData.type === AdLogoType.CUSTOM) {
      return _.omitBy({
        type: adLogoData.type,
        link: adLogoData.link,
        imgUrl: adLogoData.image.url,
        imgBase64: adLogoData.image.imgBase64
      }, _.isNil);
    }

    return {
      type: adLogoData.type
    };
  }
}

export class NativeSummaryModel extends DefaultCreativeSummaryModel {

  constructor (
    private type: string,
    creativeBasicFormData: CreativeFormBasicData,
    public nativeToBannertemplates: { [size: string]: { templatePath?: string, htmlContent?: string } },
    cloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager()
  ) {
    super(creativeBasicFormData, cloudStorageManager);
  }

  getMediaSummaryComponent (): any {
    return NativeMediaSummary;
  }

  getTypePropertiesSummary (): any {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    if (!typeProperties) {
      return {};
    }
    return {
      title: typeProperties.title,
      desc: typeProperties.desc,
      sponsor: typeProperties.sponsor,
      sponsorLink: typeProperties.sponsorLink
    };
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    let summaryData = {
      nativeToBannertemplates: this.nativeToBannertemplates,
      typeProperties: this.creativeBasicFormData.typeProperties,
      bannerUrl: this.creativeBasicFormData.bannerUrl,
      enableNativeBanner: _.get(this.creativeBasicFormData, 'enableNativeBanner', false),
      medias: {}
    };
    if (!_.isEmpty(medias)) {
      Object.keys(medias).forEach(key => {
        const data = getMediaSummaryData(medias[key]);
        if (data) {
          summaryData.medias[key] = data;
        }
      });
    }
    return summaryData;
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['creativeId', 'typeProperties', 'medias', 'nativeBanner']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    const typeProperties = _.get(this.creativeBasicFormData, 'typeProperties', {});
    Object.keys(typeProperties).forEach(key => {
      formData.append(`creative.typeProperties[${key}]`, typeProperties[key]);
    });
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    Object.keys(medias).forEach(key => {
      if (medias[key].file) {
        formData.append(`creative.typeProperties[${key}]`, medias[key].file);
      }
    });
    const enableNativeBanner = _.get(this.creativeBasicFormData, 'enableNativeBanner', false);
    formData.append('creative.enableNativeBanner', enableNativeBanner.toString());
    const isOldImageBanner = _.get(this.creativeBasicFormData, 'nativeBanner.300x250.imageUrl') !== undefined;
    const needGenH5 = enableNativeBanner && (this.type.includes('create') || isOldImageBanner);
    if (needGenH5) {
      Object.values(this.nativeToBannertemplates).forEach((template, index) => {
        template.templatePath &&
          formData.append(`creative.templates[${index}]`, template.templatePath);
      });
    }
    return formData;
  }
}

export class VideoSummaryModel extends DefaultCreativeSummaryModel {

  getMediaSummaryComponent (): any {
    return VideoMediaSummary;
  }

  getTypePropertiesSummary (): any {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    if (!typeProperties) {
      return {};
    }
    const skippableSetting = this.getSkippableSettingSummary(typeProperties.skippableSetting);
    const summary = {
      videoPlacement: this.getVideoPlacementSummary(typeProperties.videoPlacement)
    };
    if (!_.isEmpty(skippableSetting)) {
      summary['skippableSetting'] = skippableSetting;
    }
    return summary;
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    let summaryData = {
      isThirdPartyVideo: this.creativeBasicFormData.typeProperties.videoSrc === '3rd'
    };
    if (!_.isEmpty(medias)) {
      Object.keys(medias).forEach(key => {
        const data = getMediaSummaryData(medias[key]);
        if (data) {
          summaryData[key] = data;
        }
      });
    }
    return summaryData;
  }

  getSkippableSettingSummary (skippableSettingData) {
    if (!skippableSettingData) {
      return;
    }

    if (!(+skippableSettingData.skippable)) {
      return i18n.t<string>('videoForm.labels.nonSkippable');
    } else {
      return `${i18n.t<string>('videoForm.labels.skippable')}:${i18n.t<string>('creativeList.labels.skipOffset', { offset: skippableSettingData.skipOffset })}`;
    }
  }

  getVideoPlacementSummary (videoPlacement) {
    if (videoPlacement === undefined) {
      return;
    }
    switch (videoPlacement) {
      case VideoPlacement.IN_STREAM:
        return i18n.t<string>('videoForm.labels.inStream');
      case VideoPlacement.OUT_STREAM:
        return i18n.t<string>('videoForm.labels.outStream');
      default:
        return i18n.t<string>('videoForm.labels.unlimitStream');
    }
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['creativeId', 'typeProperties', 'medias']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    const typeProperties = _.omit(_.get(this.creativeBasicFormData, 'typeProperties', {}), ['videoSrc', 'skippableSetting']);
    Object.keys(typeProperties).forEach(key => {
      formData.append(`creative.typeProperties[${key}]`, typeProperties[key]);
    });
    const skippable = !!+(_.get(this.creativeBasicFormData, 'typeProperties.skippableSetting.skippable', 1));
    formData.append('creative.typeProperties[skippable]', skippable.toString());
    formData.append('creative.typeProperties[nonSkippable]', (!skippable).toString());
    skippable && formData.append('creative.typeProperties[skipOffset]', _.get(this.creativeBasicFormData, 'typeProperties.skippableSetting.skipOffset', 0));
    const videoSrc = _.get(this.creativeBasicFormData, 'typeProperties.videoSrc');
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    if (videoSrc === '3rd') {
      formData.append('creative.typeProperties[width]', '300');
      formData.append('creative.typeProperties[height]', '150');
      formData.append('creative.typeProperties[duration]', medias.urlVideo.duration);
      formData.append('creative.typeProperties[adServingUrl]', medias.urlVideo.url);
    } else {
      formData.append('creative.typeProperties[width]', medias.fileVideo.width);
      formData.append('creative.typeProperties[height]', medias.fileVideo.height);
      formData.append('creative.typeProperties[duration]', Math.floor(medias.fileVideo.duration).toString());
      formData.append('creative.typeProperties[videoUrl]', medias.fileVideo.url);
      if (medias.fileVideo.file) {
        const assetId = await this.cloudStorageManager.uploadVideoToCloud(medias.fileVideo.file);
        if (assetId) {
          formData.append('creative.typeProperties[videoAssetId]', assetId);
          const videoUrl = await this.creativeManager.publicVideoAssetId(assetId);
          formData.set('creative.typeProperties[videoUrl]', videoUrl);
        }
      }
    }

    return formData;
  }
}

export class ImageSummaryModel extends DefaultCreativeSummaryModel {

  getMediaSummaryComponent (): any {
    return ImageMediaSummary;
  }

  getTypePropertiesSummary (): any {
    return {};
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    return {
      medias: this.creativeBasicFormData.medias
    };
  }

  async getBasicSubmitData (): Promise<any> {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const imageData = _.get(medias, 'image', {});
    formData.append('creative.typeProperties[width]', imageData.width);
    formData.append('creative.typeProperties[height]', imageData.height);
    let imageUrl = imageData.url;
    if (imageData.file) {
      imageUrl = await this.cloudStorageManager.uploadFileToCloud(imageData.file);
    }
    if (imageUrl) {
      formData.append('creative.typeProperties[imageUrl]', imageUrl);
    }

    return formData;
  }
}

export class CreateImageMultipleSummaryModel extends ImageSummaryModel {

  getMediaSummaryComponent (): any {
    return ImageMultipleMediaSummary;
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    return {
      medias: this.creativeBasicFormData.medias
    };
  }

  async getBasicSubmitData () {
    return new FormData();
  }

  async getJsonSubmitData () {
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const imagesData = _.get(medias, 'images', {});
    const imagesArray: { file: File, width: number, height: number }[] = Object.values(imagesData);
    const submitData: any[] = [];
    const sizeImageMap: { [key: string]: { file: File, width: number, height: number }[] } = imagesArray.reduce((acc, image) => {
      const sizeDes = `${image.width}x${image.height}`;
      if (sizeDes in acc) {
        acc[sizeDes].push(image);
      } else {
        acc[sizeDes] = [image];
      }
      return acc;
    }, {});
    for (let size in sizeImageMap) {
      const imagesArray = sizeImageMap[size];
      for (let i = 0; i < imagesArray.length; ++i) {
        const image = imagesArray[i];
        const imageUrl = await this.cloudStorageManager.uploadFileToCloud(image.file);
        let name = `${this.creativeBasicFormData.name}_${size}`;
        if (imagesArray.length > 1) {
          name = `${name}(${i + 1})`;
        }
        submitData.push({
          creative: {
            name,
            landingPageUrl: this.creativeBasicFormData.landingPageUrl,
            creativeType: this.creativeBasicFormData.creativeType,
            advertiserId: this.creativeBasicFormData.advertiserId,
            tenmaxCategory: this.creativeBasicFormData.tenmaxCategory,
            enableStartTime: this.creativeBasicFormData.enableStartTime,
            enableEndTime: this.creativeBasicFormData.enableEndTime,
            bannerUrl: this.creativeBasicFormData.bannerUrl,
            typeProperties: {
              width: image.width.toString(),
              height: image.height.toString(),
              imageUrl
            },
            adLogo: this.creativeBasicFormData.adLogo ? this.getAdLogoData(this.creativeBasicFormData.adLogo) : undefined
          }
        });
      }
    }

    return submitData;
  }
}

export class PPSSummaryModel extends DefaultCreativeSummaryModel {

  getMediaSummaryComponent (): any {
    return PPSMediaSummary;
  }

  getTypePropertiesSummary (): any {
    const {
      layoutId,
      subLayout,
      thirdPartyType,
      htmlSnippet
    } = this.creativeBasicFormData.typeProperties;
    return {
      thirdPartyType: i18n.t<string>(`thirdPartyType.${thirdPartyType.toLowerCase()}`),
      htmlSnippet,
      layoutId: i18n.t<string>(`ppsLayoutId.${layoutId.toLowerCase().replace(/-|\s/g, '_')}`),
      subLayout
    };
  }

  getMediaSummary (): any {
    return {
      htmlSnippet: this.creativeBasicFormData.typeProperties.htmlSnippet
    };
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias', 'bannerImageUrl', 'ppsLayoutId', 'subLayout']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    const {
      htmlSnippet,
      thirdPartyType,
      layoutId,
      subLayout
    } = this.creativeBasicFormData.typeProperties;
    formData.append('creative.typeProperties[htmlSnippet]', htmlSnippet);
    formData.append('creative.typeProperties[thirdPartyType]', thirdPartyType);
    formData.append('creative.ppsLayoutId', `pps_${layoutId}`);
    subLayout && formData.append('creative.subLayout', subLayout);
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const imageData = _.get(medias, 'image', {});
    let bannerImageUrl = imageData.url;
    if (imageData.file) {
      bannerImageUrl = await this.cloudStorageManager.uploadFileToCloud(imageData.file);
    }
    if (bannerImageUrl) {
      formData.append('creative.typeProperties[bannerImageUrl]', bannerImageUrl);
    }
    return formData;
  }
}

export class HTML5SummaryModel extends DefaultCreativeSummaryModel {

  getMediaSummaryComponent (): any {
    return undefined;
  }

  getTypePropertiesSummary (): any {
    return {
      bannerSize: this.creativeBasicFormData.typeProperties.bannerSize
    };
  }

  getMediaSummary (): any {
    return {};
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias', 'bannerImageUrl']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    const widthHeight = _.get(this.creativeBasicFormData.typeProperties, 'bannerSize', '300x250').split('x');
    formData.append('creative.typeProperties[width]', widthHeight[0]);
    formData.append('creative.typeProperties[height]', widthHeight[1]);
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const file = _.get(medias, 'htmlZip.file');
    if (file) {
      formData.append('creative.typeProperties[h5File]', file);
    }
    return formData;
  }
}

export class EdiMaxSummaryModel extends DefaultCreativeSummaryModel {

  collectMediaDataStrategies = {
    [OutdoorType.VIDEO]: async (medias, formData) => {
      await this.collectVideoData(medias, formData);
    },
    [OutdoorType.VIDEO_IMAGE]: async (medias, formData) => {
      await this.collectVideoData(medias, formData);
      await this.collectImageData(medias, formData);
    },
    [OutdoorType.HTML5]: async (medias, formData) => {
      await this.collectHtmlData(medias, formData);
    },
    [OutdoorType.IMAGE_AUDIO]: async (medias, formData) => {
      await this.collectImageData(medias, formData);
      await this.collectAudioData(medias, formData);
    }
  };

  constructor (
    creativeBasicFormData,
    cloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager()
  ) {
    super(creativeBasicFormData, cloudStorageManager);
  }

  getMediaSummaryComponent (): any {
    return OutdoorMediaSummary;
  }

  getTypePropertiesSummary (): any {
    const creativeSize = _.get(this.creativeBasicFormData, 'typeProperties.creativeSize');
    const outdoorType = _.get(this.creativeBasicFormData, 'typeProperties.outdoorType');
    const temperatureEnable = _.get(this.creativeBasicFormData, 'typeProperties.temperatureEnable');
    const duration = _.get(this.creativeBasicFormData, 'typeProperties.duration');
    const linePushMessage = _.get(this.creativeBasicFormData, 'typeProperties.linePushMessage');
    const lineBeaconServiceType = _.get(this.creativeBasicFormData, 'typeProperties.lineBeaconServiceType');
    const lineBeaconHardwareId = _.get(this.creativeBasicFormData, 'typeProperties.lineBeaconHardwareId');
    const lineBotChannelSecret = _.get(this.creativeBasicFormData, 'typeProperties.lineBotChannelSecret');
    const lineBotChannelAccessToken = _.get(this.creativeBasicFormData, 'typeProperties.lineBotChannelAccessToken');
    return _.omitBy({
      creativeSize,
      outdoorType: i18n.t<string>(`outdoorForm.labels.outdoorType${outdoorType}`),
      temperatureRange: temperatureEnable && _.get(this.creativeBasicFormData, 'typeProperties.temperatureRange').map(temp => `${temp}°C`).join('-'),
      outdoorDuration: outdoorType === OutdoorType.HTML5 ?
        `${duration} ${i18n.t<string>('common.units.seconds')}` :
        i18n.t<string>(`outdoorForm.labels.duration${duration}`),
      lineBeaconServiceType: lineBeaconServiceType ? i18n.t<string>(`lineFields.labels.${lineBeaconServiceType}`) : undefined,
      lineBeaconHardwareId,
      lineBotChannelSecret,
      lineBotChannelAccessToken,
      linePushMessage
    }, _.isEmpty);
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    const useAudio = _.get(this.creativeBasicFormData, 'typeProperties.useAudio', false);
    return {
      medias: {
        ...this.creativeBasicFormData.medias,
        audio: useAudio ? this.creativeBasicFormData.medias.audio : undefined
      }
    };
  }

  collectVideoData = async (medias, formData) => {
    const videoData = _.get(medias, 'video', {});
    if (videoData.file) {
      const assetId = await this.cloudStorageManager.uploadVideoToCloud(videoData.file);
      if (assetId) {
        formData.append('creative.typeProperties[videoAssetId]', assetId);
        formData.append('creative.typeProperties[videoDuration]', Math.floor(videoData.duration).toString());
      }
    }
  }

  collectImageData = async (medias, formData) => {
    const imageData = _.get(medias, 'image', {});
    if (imageData.file) {
      const url = await this.cloudStorageManager.uploadFileToCloud(imageData.file);
      formData.append('creative.typeProperties[imageUrl]', url);
    }
  }

  collectHtmlData = async (medias, formData) => {
    const htmlData = _.get(medias, 'html', {});
    if (htmlData.file) {
      const url = await this.cloudStorageManager.uploadFileToCloud(htmlData.file);
      formData.append('creative.typeProperties[htmlUrl]', url);
    }
  }

  collectAudioData = async (medias, formData) => {
    const audioData = _.get(medias, 'audio', {});
    if (audioData.file) {
      const audioUrl = await this.cloudStorageManager.uploadFileToCloud(audioData.file);
      formData.append('creative.typeProperties[audioUrl]', audioUrl);
      formData.append('creative.typeProperties[audioDuration]', Math.floor(audioData.duration).toString());
    }
    formData.append('creative.typeProperties[useAudio]', _.get(this.creativeBasicFormData, 'typeProperties.useAudio', false));
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias', 'bannerUrl', 'landingPageUrl']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    formData.append('creative.typeProperties[outdoorType]', _.get(this.creativeBasicFormData, 'typeProperties.outdoorType'));
    formData.append('creative.typeProperties[duration]', _.get(this.creativeBasicFormData, 'typeProperties.duration'));
    const useTempControl = _.get(this.creativeBasicFormData, 'typeProperties.temperatureEnable', false);
    formData.append('creative.typeProperties[useTempControl]', useTempControl.toString());
    if (useTempControl) {
      const temperatureRange = _.get(this.creativeBasicFormData, 'typeProperties.temperatureRange', []);
      formData.append('creative.typeProperties[minTemp]', temperatureRange[0]);
      formData.append('creative.typeProperties[maxTemp]', temperatureRange[1]);
    }
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const outdoorType = _.get(this.creativeBasicFormData, 'typeProperties.outdoorType');
    const collectMediaDataStrategy = this.collectMediaDataStrategies[outdoorType];
    if (collectMediaDataStrategy) {
      await collectMediaDataStrategy(medias, formData);
    }

    const creativeSize: `${number} x ${number}` = _.get(this.creativeBasicFormData, 'typeProperties.creativeSize', []);
    const [width, height] = creativeSize.split(' x ');
    formData.append('creative.typeProperties[width]', width);
    formData.append('creative.typeProperties[height]', height);

    const lineBeaconServiceType = _.get(this.creativeBasicFormData, 'typeProperties.lineBeaconServiceType');
    if (!_.isEmpty(lineBeaconServiceType)) {
      formData.append('creative.typeProperties[linePushMessage]', _.get(this.creativeBasicFormData, 'typeProperties.linePushMessage'));
      formData.append('creative.typeProperties[lineBeaconServiceType]', _.get(this.creativeBasicFormData, 'typeProperties.lineBeaconServiceType'));
      formData.append('creative.typeProperties[lineBeaconHardwareId]', _.get(this.creativeBasicFormData, 'typeProperties.lineBeaconHardwareId'));
    }
    if (lineBeaconServiceType === LineBeaconServiceType.TENMAX) {
      formData.append('creative.typeProperties[lineBotChannelSecret]', _.get(this.creativeBasicFormData, 'typeProperties.lineBotChannelSecret'));
      formData.append('creative.typeProperties[lineBotChannelAccessToken]', _.get(this.creativeBasicFormData, 'typeProperties.lineBotChannelAccessToken'));
    }
    return formData;
  }
}

export class PICSummaryModel extends EdiMaxSummaryModel {

  getMediaSummaryComponent (): any {
    return OutdoorMediaSummary;
  }

  getTypePropertiesSummary (): any {
    const summary = super.getTypePropertiesSummary();
    const duration = _.get(this.creativeBasicFormData, 'typeProperties.duration');
    const picSummary = _.omitBy({
      ...summary,
      outdoorDuration: `${duration} ${i18n.t<string>('common.units.seconds')}`
    }, _.isEmpty);
    return picSummary;
  }
}

export class ComboSummaryModel extends DefaultCreativeSummaryModel {

  constructor (
    creativeBasicFormData,
    cloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager()
  ) {
    super(creativeBasicFormData, cloudStorageManager);
  }

  getMediaSummaryComponent (): any {
    return ComboMediaSummary;
  }

  getTypePropertiesSummary (): any {
    return {};
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    return {
      medias: this.creativeBasicFormData.medias
    };
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const videoData = _.get(medias, 'video', {});
    if (videoData.file) {
      const assetId = await this.cloudStorageManager.uploadVideoToCloud(videoData.file);
      if (assetId) {
        formData.append('creative.typeProperties[videoAssetId]', assetId);
        formData.append('creative.typeProperties[videoW]', videoData.width.toString());
        formData.append('creative.typeProperties[videoH]', videoData.height.toString());
        formData.append('creative.typeProperties[duration]', Math.floor(videoData.duration).toString());
      }
    }

    const imageData = _.get(medias, 'image', {});
    if (imageData.file) {
      const url = await this.cloudStorageManager.uploadFileToCloud(imageData.file);
      formData.append('creative.typeProperties[imageUrl]', url);
    } else {
      formData.append('creative.typeProperties[imageUrl]', imageData.url);
    }

    return formData;
  }
}

export class OneForAllDisplaySummaryModel extends DefaultCreativeSummaryModel {

  getMediaSummaryComponent (): any {
    return OneForAllDisplayMediaSummary;
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    const typeProperties = this.creativeBasicFormData.typeProperties;
    return {
      advertiserId: this.creativeBasicFormData.advertiserId,
      typeProperties: {
        ...typeProperties,
        description: typeProperties.desc,
        linkUrl: typeProperties.fbLandingUrl
      },
      medias: this.creativeBasicFormData.medias
    };
  }

  async getCreativeBasicSummaryData () {
    const basic = _.omit(this.creativeBasicFormData, ['advertiserId', 'medias', 'typeProperties', 'bannerUrl', 'landingPageUrl', 'bannerImageUrl', 'enableStartTime', 'enableEndTime']);
    return _.uniqBy(_.compact([
      ...this.transferObjectToSummaryData(basic), {
        label: i18n.t<string>('creativeSetupFlow.labels.rtbBannerUrl'),
        value: this.creativeBasicFormData.bannerUrl
      },
      ...this.transferObjectToSummaryData(this.getTypePropertiesSummary())
    ]), 'label');
  }

  getTypePropertiesSummary (): any {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    if (!typeProperties) {
      return {};
    }
    const pageData = renderFBPage(i18n.t<string>(`fbPages.${typeProperties.pageId}`), typeProperties.pageId);
    return _.omitBy({
      fbClickUrl: typeProperties.fbLandingUrl,
      title: typeProperties.title,
      message: typeProperties.message,
      desc: typeProperties.desc,
      sponsor: typeProperties.sponsor,
      sponsorLink: typeProperties.sponsorLink,
      deepLink: typeProperties.deepLink,
      callToAction: typeProperties.callToAction,
      pageId: pageData
    }, (value) => _.isUndefined(value) || _.isNull(value));
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    const typeProperties = _.get(this.creativeBasicFormData, 'typeProperties', {});
    Object.keys(typeProperties).forEach(key => {
      formData.append(`creative.typeProperties[${key}]`, typeProperties[key]);
    });
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const squareImgFile = _.get(medias, 'squareImg.file');
    let squareImgUrl = _.get(medias, 'squareImg.url');
    if (squareImgFile) {
      squareImgUrl = await this.cloudStorageManager.uploadFileToCloud(squareImgFile);
    }
    formData.append(`creative.typeProperties[squareUrl]`, squareImgUrl);
    const storyImg = _.get(medias, 'storyImg');
    if (storyImg) {
      let storyImgUrl = _.get(storyImg, 'url');
      const storyImgFile = _.get(storyImg, 'file');
      if (storyImgFile) {
        storyImgUrl = await this.cloudStorageManager.uploadFileToCloud(storyImgFile);
      }
      formData.append(`creative.typeProperties[storyImgUrl]`, storyImgUrl);
    }

    const rectImgFile = _.get(medias, 'rectImg.file');
    let rectImgUrl = _.get(medias, 'rectImg.url');
    if (rectImgFile) {
      rectImgUrl = await this.cloudStorageManager.uploadFileToCloud(rectImgFile);
    } else if (!rectImgUrl) {
      rectImgUrl = await this.creativeManager.generateRectImgBySquareImg(squareImgUrl);
    }
    formData.append(`creative.typeProperties[rectUrl]`, rectImgUrl);

    return formData;
  }
}

export class OneForAllVideoSummaryModel extends DefaultCreativeSummaryModel {

  videoData?: any;
  storyVideoData?: any;

  getMediaSummaryComponent (): any {
    return OneForAllVideoMediaSummary;
  }

  async getCreativeBasicSummaryData (
    getUploadedFileData?: (file: File) => Promise<any>,
    addUploadedFilesData?: (file: File, data: any) => Promise<void>
  ) {
    const tryGetCachedFileData = async (file: File, onGet, onGetFailed) => {
      let data = getUploadedFileData ? await getUploadedFileData(file) : undefined;
      data ? await onGet(data) : await onGetFailed();
    };
    const basic = _.omit(this.creativeBasicFormData, ['advertiserId', 'medias', 'typeProperties', 'bannerUrl', 'landingPageUrl', 'bannerImageUrl', 'enableStartTime', 'enableEndTime', 'enableNativeBanner']);
    const video = _.get(this.creativeBasicFormData, 'medias.video');
    const storyVideo = _.get(this.creativeBasicFormData, 'medias.storyVideo');
    const videoThumbnailUrl = _.get(this.creativeBasicFormData, 'medias.videoThumbnail.url');
    const storyVideoThumbnailUrl = _.get(this.creativeBasicFormData, 'medias.storyVideoThumbnail.url');
    const advertiserId = this.creativeBasicFormData.advertiserId;
    const videoId = _.get(this.creativeBasicFormData, 'typeProperties.videoId');
    const storyVideoId = _.get(this.creativeBasicFormData, 'typeProperties.storyVideoId');
    this.videoData = {
      videoId,
      videoUrl: video.url,
      thumbnailUrl: videoThumbnailUrl
    };
    this.storyVideoData = storyVideo ? {
      storyVideoId,
      storyVideoUrl: storyVideo.url,
      storyThumbnailUrl: storyVideoThumbnailUrl
    } : undefined;

    let videoData: { title: string, url: string }[] = [];
    if (video.file && advertiserId) {
      await tryGetCachedFileData(video.file, async data => {
        this.videoData = {
          ...this.videoData,
          ...data
        };
      }, async () => {
        const url = await this.cloudStorageManager.uploadFileToCloud(video.file);
        videoData.push({
          title: '1x1',
          url
        });
      });
    }
    if (storyVideo && storyVideo.file && advertiserId) {
      await tryGetCachedFileData(storyVideo.file, async data => {
        this.storyVideoData = {
          ...this.storyVideoData,
          ...data
        };
      }, async () => {
        const url = await this.cloudStorageManager.uploadFileToCloud(storyVideo.file);
        videoData.push({
          title: '9x16',
          url
        });
      });
    }
    await this.prepareFBVideo(videoData);
    await this.prepareThumbnailUrl(tryGetCachedFileData, addUploadedFilesData);
    return _.compact([
      ...this.transferObjectToSummaryData(basic),
      ...this.transferObjectToSummaryData(this.getTypePropertiesSummary())
    ]);
  }

  async prepareFBVideo (
    videoData: {
      title: string,
      url: string
    }[],
    addUploadedFilesData?: (file: File, data: any) => Promise<void>
  ) {
    const advertiserId = this.creativeBasicFormData.advertiserId;
    const video = _.get(this.creativeBasicFormData, 'medias.video');
    const storyVideo = _.get(this.creativeBasicFormData, 'medias.storyVideo');
    if (videoData.length > 0 && advertiserId) {
      const createdFBVideoData = await this.creativeManager.createFBVideoAndThumbnail(videoData, advertiserId, true);
      const newVideoData = createdFBVideoData.find(data => data.title === '1x1');
      const oldVideoData = videoData.find(data => data.title === '1x1');
      if (newVideoData && oldVideoData) {
        this.videoData = {
          ...this.videoData,
          thumbnailUrl: newVideoData.thumbnail,
          videoId: newVideoData.video_id,
          videoUrl: oldVideoData.url
        };
        addUploadedFilesData && addUploadedFilesData(video.file, {
          ...this.videoData
        });
      }
      const newStoryVideoData = createdFBVideoData.find(data => data.title === '9x16');
      const oldStoryVideoData = videoData.find(data => data.title === '9x16');
      if (newStoryVideoData && oldStoryVideoData) {
        this.storyVideoData = {
          ...this.storyVideoData,
          storyThumbnailUrl: newStoryVideoData.thumbnail,
          storyVideoId: newStoryVideoData.video_id,
          storyVideoUrl: oldStoryVideoData.url
        };
        addUploadedFilesData && addUploadedFilesData(storyVideo.file, {
          ...this.storyVideoData
        });
      }
    }
  }

  async prepareThumbnailUrl (
    tryGetCachedFileData: (file: File, onGet, onGetFailed) => Promise<void>,
    addUploadedFilesData?: (file: File, data: any) => Promise<void>
  ) {
    const advertiserId = this.creativeBasicFormData.advertiserId;
    const videoId = _.get(this.creativeBasicFormData, 'typeProperties.videoId');
    const storyVideoId = _.get(this.creativeBasicFormData, 'typeProperties.storyVideoId');
    const video = _.get(this.creativeBasicFormData, 'medias.video');
    const storyVideo = _.get(this.creativeBasicFormData, 'medias.storyVideo');
    const videoThumbnailFile = _.get(this.creativeBasicFormData, 'medias.videoThumbnail.file');
    const storyVideoThumbnailFile = _.get(this.creativeBasicFormData, 'medias.storyVideoThumbnail.file');
    if (videoThumbnailFile) {
      await tryGetCachedFileData(videoThumbnailFile, async data => {
        this.videoData.thumbnailUrl = data.url;
      }, async () => {
        const url = await this.cloudStorageManager.uploadFileToCloud(videoThumbnailFile);
        this.videoData.thumbnailUrl = url;
        addUploadedFilesData && addUploadedFilesData(videoThumbnailFile, { url });
      });
    }
    if (storyVideoThumbnailFile) {
      await tryGetCachedFileData(storyVideoThumbnailFile, async data => {
        this.storyVideoData.storyThumbnailUrl = data.url;
      }, async () => {
        const url = await this.cloudStorageManager.uploadFileToCloud(storyVideoThumbnailFile);
        this.storyVideoData.storyThumbnailUrl = url;
        addUploadedFilesData && addUploadedFilesData(storyVideoThumbnailFile, { url });
      });
    }
    let videoShouldGetThumbFormRemote: any[] = [];
    if (!this.videoData.thumbnailUrl && !video.file) {
      videoShouldGetThumbFormRemote.push(videoId);
    }
    if (storyVideo && this.storyVideoData && !this.storyVideoData.storyThumbnailUrl && !storyVideo.file) {
      videoShouldGetThumbFormRemote.push(storyVideoId);
    }
    if (videoShouldGetThumbFormRemote.length > 0 && advertiserId) {
      const thumbnailUrlData = await this.creativeManager.getFBVideoThumbnail(advertiserId, videoShouldGetThumbFormRemote);
      const thumbnailUrl = thumbnailUrlData.find(data => data.title === '1x1');
      if (thumbnailUrl) {
        this.videoData.thumbnailUrl = thumbnailUrl.thumbnail;
      }
      const storyThumbnailUrl = thumbnailUrlData.find(data => data.title === '9x16');
      if (storyThumbnailUrl) {
        this.storyVideoData.storyThumbnailUrl = storyThumbnailUrl.thumbnail;
      }
    }
  }

  getTypePropertiesSummary (): any {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    if (!typeProperties) {
      return {};
    }
    const pageData = renderFBPage(i18n.t<string>(`fbPages.${typeProperties.pageId}`), typeProperties.pageId);
    return {
      title: typeProperties.title,
      message: typeProperties.message,
      shortMessage: typeProperties.shortMessage,
      fbVideoDesc: typeProperties.description,
      callToAction: typeProperties.callToAction,
      pageId: pageData
    };
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    const videoData = this.videoData ? this.videoData : {};
    const storyVideoData = this.storyVideoData ? this.storyVideoData : {};
    return {
      advertiserId: this.creativeBasicFormData.advertiserId,
      typeProperties: {
        ...this.creativeBasicFormData.typeProperties,
        ...videoData,
        ...storyVideoData,
        linkUrl: this.creativeBasicFormData.bannerUrl,
        imgUrl: videoData.thumbnailUrl
      },
      medias: this.creativeBasicFormData.medias
    };
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias', 'enableNativeBanner', 'bannerUrl']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    const typeProperties = _.omit(_.get(this.creativeBasicFormData, 'typeProperties', {}), ['videoId', 'storyVideoId']);
    Object.keys(typeProperties).forEach(key => {
      formData.append(`creative.typeProperties[${key}]`, typeProperties[key]);
    });

    const videoMedia = _.get(this.creativeBasicFormData, 'medias.video');
    if (videoMedia) {
      formData.append(`creative.typeProperties[width]`, videoMedia.width);
      formData.append(`creative.typeProperties[height]`, videoMedia.height);
    }
    if (this.videoData) {
      formData.append(`creative.typeProperties[videoId]`, this.videoData.videoId);
      formData.append(`creative.typeProperties[videoUrl]`, this.videoData.videoUrl);
      formData.append(`creative.typeProperties[thumbnailUrl]`, this.videoData.thumbnailUrl);
    }
    if (this.storyVideoData) {
      formData.append(`creative.typeProperties[storyVideoId]`, this.storyVideoData.storyVideoId);
      formData.append(`creative.typeProperties[storyVideoUrl]`, this.storyVideoData.storyVideoUrl);
      formData.append(`creative.typeProperties[storyThumbnailUrl]`, this.storyVideoData.storyThumbnailUrl);
    }
    this.creativeBasicFormData['bannerUrl'] && formData.append('creative.typeProperties[fbLandingUrl]', this.creativeBasicFormData['bannerUrl']);
    return formData;
  }
}

export class AdNeonSummaryModel extends DefaultCreativeSummaryModel {

  constructor (
    creativeBasicFormData,
    cloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager()
  ) {
    super(creativeBasicFormData, cloudStorageManager);
  }

  getMediaSummaryComponent (): any {
    return AdNeonMediaSummary;
  }

  getTypePropertiesSummary (): any {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    return {
      layout: typeProperties.layout === RETAILMAX_LAYOUT_TYPE.VIDEO ?
        i18n.t<string>('retailMaxForm.options.videoLayout') :
        i18n.t<string>('retailMaxForm.options.imageLayout')
    };
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    return {
      medias: this.creativeBasicFormData.medias
    };
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    this.creativeBasicFormData.name && formData.append('creative.typeProperties[assetName]', this.creativeBasicFormData.name);
    this.creativeBasicFormData.bannerUrl && formData.append('creative.typeProperties[landingUrl]', this.creativeBasicFormData.bannerUrl);
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const videoData = _.get(medias, 'video', {});
    const imageData = _.get(medias, 'image', {});
    const layout = _.get(this.creativeBasicFormData, 'typeProperties.layout');
    formData.append('creative.typeProperties[layoutId]', layout.replace('pps_', ''));
    if (videoData.file) {
      const assetId = await this.cloudStorageManager.uploadVideoToCloud(videoData.file);
      const fileCheckSum = await computeChecksumMd5(videoData.file);
      if (assetId) {
        formData.append('creative.typeProperties[videoAssetId]', assetId);
        formData.append('creative.typeProperties[videoChecksum]', fileCheckSum);
        formData.append('creative.typeProperties[videoSize]', videoData.file.size);
      }
    } else if (imageData.file) {
      const imageUrl = await this.cloudStorageManager.uploadFileToCloud(imageData.file);
      formData.append('creative.typeProperties[imageUrl]', imageUrl);
    }
    return formData;
  }
}

export class RetailRichMediaProductSummaryModel extends DefaultCreativeSummaryModel {

  logoUrl?: string;
  videoData?: any;

  constructor (
    creativeBasicFormData,
    private initProductSetId,
    cloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager()
  ) {
    super(creativeBasicFormData, cloudStorageManager);
  }

  async getCreativeBasicSummaryData () {
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const logoData = _.get(medias, 'logo', {});
    if (logoData.file) {
      this.logoUrl = await this.cloudStorageManager.uploadFileToCloud(logoData.file);
    }
    const videoData = _.get(medias, 'video', {});
    if (videoData.file) {
      try {
        const assetId = await this.cloudStorageManager.uploadVideoToCloud(videoData.file);
        if (assetId) {
          const videoUrl = await this.creativeManager.publicVideoAssetId(assetId);
          const fileCheckSum = await computeChecksumMd5(videoData.file);
          this.videoData = {
            videoUrl,
            videoAssetId: assetId,
            videoChecksum: fileCheckSum,
            videoSize: videoData.file.size.toString()
          };
        }
      } catch (e) {}
    } else {
      this.videoData = {
        videoUrl: videoData.url
      };
    }
    return super.getCreativeBasicSummaryData();
  }

  getMediaSummaryComponent (): any {
    return RetailProductMediaSummary;
  }

  getTypePropertiesSummary (): any {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    return {
      retailer: i18n.t<string>(`retailers.${typeProperties.retailer}`),
      productSet: i18n.t<string>(`retail.productSets.${typeProperties.productSetId}`),
      retailLayout: i18n.t<string>(`adneon.layouts.${_.camelCase(typeProperties.layout)}`),
      retailLandingUrl: typeProperties.retailLandingUrl,
      displaySize: i18n.t<string>(`adNeonForm.options.displaySize.${typeProperties.displaySize}`),
      retailLogoPosition: i18n.t<string>(`adNeonForm.options.logoPosition.${typeProperties.logoPosition}`),
      retailCallToAction: i18n.t<string>(`callToAction.${_.snakeCase(typeProperties.callToAction)}`),
      maxCardsCount: typeProperties.maxCount,
      autoAnimationSeconds: typeProperties.autoAnimationSeconds
    };
  }

  getMediaSummary (): any {
    const callToAction = _.get(this.creativeBasicFormData, 'typeProperties.callToAction');
    const videoData = this.videoData ? this.videoData : {};
    const productSetId = _.get(this.creativeBasicFormData, 'typeProperties.productSetId', '').toString();
    const cloudStorageConfig = config.cloudStorageConfig;
    return {
      id: this.creativeBasicFormData.creativeId,
      advertiserId: this.creativeBasicFormData.advertiserId,
      typeProperties: {
        assetName: this.creativeBasicFormData.name,
        retailLandingUrl: _.get(this.creativeBasicFormData, 'typeProperties.retailLandingUrl'),
        displaySize: _.get(this.creativeBasicFormData, 'typeProperties.displaySize'),
        logoPosition: _.get(this.creativeBasicFormData, 'typeProperties.logoPosition'),
        maxCount: _.get(this.creativeBasicFormData, 'typeProperties.maxCount', 0).toString(),
        autoAnimationSeconds: _.get(this.creativeBasicFormData, 'typeProperties.autoAnimationSeconds', 0).toString(),
        callToAction: i18n.t<string>(`callToAction.${_.snakeCase(callToAction)}`),
        layoutId: _.get(this.creativeBasicFormData, 'typeProperties.layout'),
        logo: this.logoUrl,
        retail: _.get(this.creativeBasicFormData, 'typeProperties.retailer'),
        productSet: _.get(this.creativeBasicFormData, 'typeProperties.productSet'),
        productSetId: productSetId,
        productSetUrl: `https://storage.googleapis.com/${cloudStorageConfig.bucket}/${cloudStorageConfig.creative_container}/productSetSnapshot/${productSetId}.json?t=${Date.now()}`,
        ...videoData
      }
    };
  }

  getSubmitAlertMessage () {
    const newProductSetId = _.get(this.creativeBasicFormData, 'typeProperties.productSetId');
    if (this.initProductSetId && newProductSetId !== this.initProductSetId) {
      return i18n.t<string>('productSet.labels.changingProductSetAlert');
    }
    return undefined;
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'bannerExtra', 'typeProperties', 'medias']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    this.creativeBasicFormData.name && formData.append('creative.typeProperties[assetName]', this.creativeBasicFormData.name);
    const retailLandingUrl = _.get(this.creativeBasicFormData, 'typeProperties.retailLandingUrl');
    formData.append('creative.typeProperties[retailLandingUrl]', retailLandingUrl);
    const displaySize = _.get(this.creativeBasicFormData, 'typeProperties.displaySize');
    formData.append('creative.typeProperties[displaySize]', displaySize);
    const logoPosition = _.get(this.creativeBasicFormData, 'typeProperties.logoPosition');
    formData.append('creative.typeProperties[logoPosition]', logoPosition);
    const maxCount = _.get(this.creativeBasicFormData, 'typeProperties.maxCount');
    formData.append('creative.typeProperties[maxCount]', maxCount);
    const autoAnimationSeconds = _.get(this.creativeBasicFormData, 'typeProperties.autoAnimationSeconds');
    formData.append('creative.typeProperties[autoAnimationSeconds]', autoAnimationSeconds);
    const callToAction = _.get(this.creativeBasicFormData, 'typeProperties.callToAction');
    formData.append('creative.typeProperties[callToAction]', i18n.t<string>(`callToAction.${_.snakeCase(callToAction)}`));
    const layout = _.get(this.creativeBasicFormData, 'typeProperties.layout');
    formData.append('creative.typeProperties[layoutId]', layout);
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const videoData = _.get(medias, 'video', {});
    if (this.videoData.videoAssetId) {
      formData.append('creative.typeProperties[videoAssetId]', this.videoData.videoAssetId);
      formData.append('creative.typeProperties[videoChecksum]', this.videoData.videoChecksum);
      formData.append('creative.typeProperties[videoSize]', this.videoData.videoSize);
    } else {
      formData.append('creative.typeProperties[videoAssetId]', videoData.videoAssetId);
      formData.append('creative.typeProperties[videoChecksum]', videoData.videoChecksum);
      formData.append('creative.typeProperties[videoSize]', videoData.videoSize);
    }
    if (this.logoUrl) {
      formData.append('creative.typeProperties[logo]', this.logoUrl);
    }
    const retailer = _.get(this.creativeBasicFormData, 'typeProperties.retailer');
    formData.append('creative.typeProperties[retail]', retailer);
    const productSet = _.get(this.creativeBasicFormData, 'typeProperties.productSet');
    formData.append('creative.typeProperties[productSet]', productSet);
    const productSetId = _.get(this.creativeBasicFormData, 'typeProperties.productSetId');
    formData.append('creative.typeProperties[productSetId]', productSetId);
    return formData;
  }
}

export class RetailNativeProductSummaryModel extends DefaultCreativeSummaryModel {

  logoUrl?: string;
  constructor (
    creativeBasicFormData,
    private initProductSetId,
    cloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager()
  ) {
    super(creativeBasicFormData, cloudStorageManager);
  }

  getMediaSummaryComponent (): any {
    return RetailProductMediaSummary;
  }

  getTypePropertiesSummary (): any {
    const typeProperties = this.creativeBasicFormData.typeProperties;
    return {
      retailer: i18n.t<string>(`retailers.${typeProperties.retailer}`),
      productSet: i18n.t<string>(`retail.productSets.${typeProperties.productSetId}`),
      retailLayout: i18n.t<string>(`adneon.layouts.${_.camelCase(typeProperties.layout)}`),
      retailCallToAction: i18n.t<string>(`callToAction.${_.snakeCase(typeProperties.callToAction)}`)
    };
  }

  async getCreativeBasicSummaryData () {
    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    const logoData = _.get(medias, 'logo', {});
    if (logoData.file) {
      this.logoUrl = await this.cloudStorageManager.uploadFileToCloud(logoData.file);
    }
    return super.getCreativeBasicSummaryData();
  }

  getMediaSummary (): any {
    const productSetId = _.get(this.creativeBasicFormData, 'typeProperties.productSetId', '').toString();
    const callToAction = _.get(this.creativeBasicFormData, 'typeProperties.callToAction');
    const cloudStorageConfig = config.cloudStorageConfig;
    return {
      id: this.creativeBasicFormData.creativeId,
      advertiserId: this.creativeBasicFormData.advertiserId,
      typeProperties: {
        assetName: this.creativeBasicFormData.name,
        callToAction: i18n.t<string>(`callToAction.${_.snakeCase(callToAction)}`),
        layoutId: _.get(this.creativeBasicFormData, 'typeProperties.layout'),
        logo: this.logoUrl,
        retail: _.get(this.creativeBasicFormData, 'typeProperties.retailer'),
        productSet: _.get(this.creativeBasicFormData, 'typeProperties.productSet'),
        productSetId: productSetId,
        productSetUrl: `https://storage.googleapis.com/${cloudStorageConfig.bucket}/${cloudStorageConfig.creative_container}/productSetSnapshot/${productSetId}.json?t=${Date.now()}`
      }
    };
  }

  getSubmitAlertMessage () {
    const newProductSetId = _.get(this.creativeBasicFormData, 'typeProperties.productSetId');
    if (this.initProductSetId && newProductSetId !== this.initProductSetId) {
      return i18n.t<string>('productSet.labels.changingProductSetAlert');
    }
    return undefined;
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'bannerExtra', 'typeProperties', 'medias']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    this.creativeBasicFormData.name && formData.append('creative.typeProperties[assetName]', this.creativeBasicFormData.name);
    const callToAction = _.get(this.creativeBasicFormData, 'typeProperties.callToAction');
    formData.append('creative.typeProperties[callToAction]', i18n.t<string>(`callToAction.${_.snakeCase(callToAction)}`));
    const layout = _.get(this.creativeBasicFormData, 'typeProperties.layout');
    formData.append('creative.typeProperties[layoutId]', layout);
    if (this.logoUrl) {
      formData.append('creative.typeProperties[logo]', this.logoUrl);
    }
    const retailer = _.get(this.creativeBasicFormData, 'typeProperties.retailer');
    formData.append('creative.typeProperties[retail]', retailer);
    const productSet = _.get(this.creativeBasicFormData, 'typeProperties.productSet');
    formData.append('creative.typeProperties[productSet]', productSet);
    const productSetId = _.get(this.creativeBasicFormData, 'typeProperties.productSetId');
    formData.append('creative.typeProperties[productSetId]', productSetId);
    return formData;
  }
}

export class PilotTVSummaryModel extends DefaultCreativeSummaryModel {

  getMediaSummaryComponent (): any {
    return VideoMediaSummary;
  }

  getTypePropertiesSummary (): any {
    return {};
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    let summaryData = {};
    if (!_.isEmpty(medias)) {
      Object.keys(medias).forEach(key => {
        const data = getMediaSummaryData(medias[key]);
        if (data) {
          summaryData[key] = data;
        }
      });
    }
    return summaryData;
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['creativeId', 'typeProperties', 'medias']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    const typeProperties = _.get(this.creativeBasicFormData, 'typeProperties', {});
    Object.keys(typeProperties).forEach(key => {
      formData.append(`creative.typeProperties[${key}]`, typeProperties[key]);
    });
    formData.append('creative.typeProperties[videoPlacement]', VideoPlacement.IN_STREAM.toString());
    formData.append('creative.typeProperties[skippable]', 'false');
    formData.append('creative.typeProperties[nonSkippable]', 'true');
    formData.append('creative.typeProperties[skipOffset]', '0');

    const medias = _.get(this.creativeBasicFormData, 'medias', {});
    formData.append('creative.typeProperties[width]', medias.fileVideo.width);
    formData.append('creative.typeProperties[height]', medias.fileVideo.height);
    formData.append('creative.typeProperties[duration]', Math.floor(medias.fileVideo.duration).toString());
    formData.append('creative.typeProperties[videoUrl]', medias.fileVideo.url);
    if (medias.fileVideo.file) {
      const assetId = await this.cloudStorageManager.uploadVideoToCloud(medias.fileVideo.file);
      if (assetId) {
        formData.append('creative.typeProperties[videoAssetId]', assetId);
        const videoUrl = await this.creativeManager.publicVideoAssetId(assetId);
        formData.set('creative.typeProperties[videoUrl]', videoUrl);
      }
    }

    formData.append('creative.dealIds[0]', 'PilotTV');
    return formData;
  }
}

export class CustomLayoutSummaryModel extends DefaultCreativeSummaryModel {

  constructor (
    creativeBasicFormData: CreativeFormBasicData,
    cloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager(),
    creativeManager: CreativeManager = new DefaultCreativeManager(),
    private customLayoutManager: CustomLayoutManager = new DefaultCustomLayoutManager()
  ) {
    super(creativeBasicFormData, cloudStorageManager, creativeManager);
  }

  getMediaSummaryComponent (): any {
    return CustomLayoutMediaSummary;
  }

  getTypePropertiesSummary (): any {
    const {
      customLayout,
      macros
    } = this.creativeBasicFormData.typeProperties;
    const macrosConfig = _.defaultTo(customLayout.macros, {});
    const macroSummary = _.mapKeys(macros, (value, key) => {
      const macroConfig = macrosConfig[key];
      return macroConfig.name;
    });
    return {
      customLayout: customLayout.name,
      ...macroSummary
    };
  }

  getMediaSummary (): any {
    const medias = this.creativeBasicFormData.medias;
    if (!medias) {
      return {};
    }
    return {
      medias: this.creativeBasicFormData.medias
    };
  }

  async getBasicSubmitData () {
    const formData = new FormData();
    this.setBasicFormData(formData, ['advertiserId', 'creativeId', 'typeProperties', 'medias', 'bannerImageUrl', 'ppsLayoutId']);
    this.creativeBasicFormData['creativeId'] && formData.append('creative.id', this.creativeBasicFormData['creativeId'].toString());
    formData.append('creative.advertiserId', this.creativeBasicFormData['advertiserId'] ? this.creativeBasicFormData['advertiserId'].toString() : '0');
    const {
      customLayoutId,
      customLayout,
      macros
    } = this.creativeBasicFormData.typeProperties;
    formData.append('creative.ppsLayoutId', `pps_${customLayout.layoutId}`);
    formData.append('creative.typeProperties[customLayoutId]', customLayoutId);
    const mediaData = {
      ..._.get(this.creativeBasicFormData.medias, 'image', {}),
      ..._.get(this.creativeBasicFormData.medias, 'video', {})
    };
    const macrosMap: { [key: string]: string } = {};
    Object.keys(_.defaultTo(macros, {})).forEach((key) => {
      macrosMap[key] = macros[key];
    });
    const macroValueMap = await this.customLayoutManager.getMacroValueMap(mediaData, customLayout.macros);
    Object.keys(macroValueMap).forEach(key => {
      macrosMap[key] = macroValueMap[key];
    });
    formData.append('creative.typeProperties[macros]', JSON.stringify(macrosMap));
    return formData;
  }
}
