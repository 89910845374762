import { AbstractFormContentModel } from './FormContentModel';
import _ from 'lodash';
import i18n from 'i18n';
import VideoForm from './VideoForm';
import { Creative, VideoPlacement } from 'core/creative/Creative';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { BasicFormProps } from './BasicFormProps';
import { LimitationData } from 'core/limitation/Limitation';

const mimeDB = require('mime-db');

export type VideoFormProps = {
  readonly model: VideoFormModel;
} & BasicFormProps;

export class VideoFormModel extends AbstractFormContentModel {
  public needBannerUrl: boolean = true;

  addLimitation?: (
    operate: string,
    limitationType: string,
    label: string,
    value: string
  ) => void;

  setLimitationHook (
    addLimitation: (
      operate: string,
      limitationType: string,
      label: string,
      value: string
    ) => void
  ): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = (): any => {
    return VideoForm;
  }

  validateTenMaxVideo = fileData => {
    const validTypes = ['video/mp4'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', {
        type: extensions[0]
      });
    }

    const errors: any[] = [];
    const duration = fileData.duration;
    if (duration > 120) {
      errors.push(
        i18n.t<string>('creativeSetupFlow.labels.videoDurationError')
      );
    }

    if (file.size > 10485760) {
      errors.push(
        i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', {
          storage1: formatBytes(file.size),
          storage2: '10MB'
        })
      );
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getInitTypeProperties () {
    return {
      videoSrc: 'tenmax',
      videoPlacement: VideoPlacement.IN_STREAM,
      skippableSetting: {
        skippable: 0
      }
    };
  }

  getFormModelData (creative: Creative) {
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const adServingUrl = _.get(creativeValue, 'adServingUrl');
    const videoUrl = _.get(creativeValue, 'videoUrl');
    const skippable = _.get(creativeValue, 'skippable', true);
    const typeProperties = _.omitBy(
      {
        videoSrc: adServingUrl ? '3rd' : 'tenmax',
        videoPlacement: this.getVideoPlacement({
          inStream: _.get(creativeValue, 'inStream'),
          outStream: _.get(creativeValue, 'outStream')
        }),
        skippableSetting: {
          skippable: skippable ? 1 : 0,
          skipOffset: _.get(creativeValue, 'skipOffset', 0)
        }
      },
      _.isUndefined
    );
    const medias = _.omitBy(
      {
        urlVideo: adServingUrl && {
          url: adServingUrl,
          duration: _.get(creativeValue, 'duration')
        },
        fileVideo: !adServingUrl &&
          videoUrl && {
            url: videoUrl,
            duration: _.get(creativeValue, 'duration'),
            width: _.get(creativeValue, 'w'),
            height: _.get(creativeValue, 'h')
          }
      },
      _.isEmpty
    );
    const creativeBasic: any = _.omit(creative.basic, [
      'id',
      'srcType',
      'creativeValues'
    ]);
    return {
      basic: {
        ...creativeBasic,
        typeProperties,
        medias
      },
      limitations: creative.limitations
    };
  }

  getVideoPlacement ({ inStream, outStream }) {
    if (inStream && outStream) {
      return VideoPlacement.UNLIMIT;
    }
    if (inStream) {
      return VideoPlacement.IN_STREAM;
    }
    if (outStream) {
      return VideoPlacement.OUT_STREAM;
    }
    return VideoPlacement.UNLIMIT;
  }

  override getInitLimitations (): LimitationData {
    return {
      include: [
        {
          type: 'device',
          op: 'inc',
          value: [
            {
              value: '2',
              label: 'PC'
            },
            {
              value: '3',
              label: 'Connected_tv'
            },
            {
              value: '4',
              label: 'Phone'
            },
            {
              value: '5',
              label: 'Tablet'
            },
            {
              value: '6',
              label: 'Connected_device'
            },
            {
              value: '7',
              label: 'Set_top_box'
            }
          ]
        }
      ],
      exclude: [],
      nonPreferred: [],
      other: [],
      preferred: []
    };
  }
}
